import { useState, type FC } from "react";
import { Merchant } from "../model/merchant";
import { MerchantSaleItem } from "../model/pos";
import { Discount } from "../model/discount";
import { parseVarian } from "../utils/helper";
import { money } from "../utils/number";
import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import CurrencyInput from "react-currency-input-field";

interface OrderItemsProps {
  merchant?: Merchant | null;
  salesItems: MerchantSaleItem[];
  checkDiscounts: (item: MerchantSaleItem) => Discount[];
  editItem: (
    item: MerchantSaleItem,
    qty: number,
    refresh?: boolean,
    discount?: Discount | null
  ) => void;
  delItem: (item: MerchantSaleItem) => void;
}

const OrderItems: FC<OrderItemsProps> = ({
  merchant,
  salesItems,
  checkDiscounts,
  editItem,
  delItem,
}) => {
  const [mountQty, setMountQty] = useState(true);
  const clearMountQty = () => {
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 50);
  };
  return (
    <ul className="flex-1 overflow-y-auto">
      {salesItems.map((e) => {
        let discItems = checkDiscounts(e);
        return (
          <li
            key={e.uniqueId}
            className=" flex justify-between items-center hover:bg-gray-100 transition-colors  last:border-b-0  border-b px-2 py-4 cursor-pointer"
          >
            <div className="flex">
              <div className="xl:w-16 xl:h-16 md:w-10  xl:block md:hidden md:h-10 mr-2 ">
                {e.product.default_picture ? (
                  <img
                    src={e.product.default_picture}
                    alt=""
                    className="rounded-md h-full w-full object-cover bg-gray-600"
                  />
                ) : (
                  <img
                    src="/images/logo-niaga.png"
                    alt=""
                    className="rounded-md  w-full object-cover bg-gray-600"
                  />
                )}
              </div>
              <div className="flex flex-col">
                <p className="font-semibold md:text-base ">{e.product.name}</p>
                {e.varian != null ? (
                  <p className=" -mt-1 font-xs">
                    {parseVarian(e.varian!.data, true)}
                  </p>
                ) : null}
                <div className="flex text-xl  md:text-base font-semibold text-orange-600 -mb-1">
                  {money((e.price - (e.discount_amount ?? 0)) * e.qty)}
                </div>
                <small className="">
                  {money(e.price - (e.discount_amount ?? 0))}
                </small>
                {discItems.length == 1 && e.discount == null && (
                  <small
                    onClick={() => {
                      editItem(e, e.qty, true, discItems[0]);
                      clearMountQty()
                    }}
                    className=" text-orange-600"
                  >
                    Terapkan diskon{" "}
                    {discItems[0].type == 1
                      ? `${discItems[0].percent}%`
                      : discItems[0].amount}
                  </small>
                )}
              </div>
            </div>
            <div className="">
              <div className="flex items-center">
                <MinusCircleIcon
                  onClick={() => {
                    editItem(e, e.qty - 1, true);
                    if (e.qty - 1 <= 0) {
                      delItem(e);
                      clearMountQty()
                    }
                    clearMountQty()
                  }}
                  className="w-6 text-blue-500"
                />
                {mountQty ? (
                  <CurrencyInput
                    className="bg-white mx-2 w-16 text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    groupSeparator="."
                    decimalSeparator=","
                    placeholder="Jumlah"
                    defaultValue={e.qty}
                    onSubmit={() => {
                      setMountQty(false);
                      setTimeout(() => {
                        setMountQty(true);
                      }, 10);
                    }}
                    onValueChange={(value, _, values) => {
                      editItem(e, values?.float ?? e.qty, false);
                    }}
                  />
                ): <div className="bg-white mx-2 w-16 text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">{money(e.qty)}</div> }
                <PlusCircleIcon
                  onClick={() => {
                    editItem(e, e.qty + 1, true);
                    clearMountQty()
                  }}
                  className="w-6 text-green-500"
                />
                <TrashIcon
                  onClick={() => {
                    delItem(e);
                    clearMountQty()
                  }}
                  className="ml-2 w-6 text-red-500"
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
export default OrderItems;
