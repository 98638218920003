import Konva from "konva";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { BiCopy, BiPolygon, BiTrash } from "react-icons/bi";
import { FaFloppyDisk } from "react-icons/fa6";
import { PiRectangleLight } from "react-icons/pi";
import { RiBringForward, RiSendToBack } from "react-icons/ri";
import {
  Circle,
  Ellipse,
  Group,
  Layer,
  Line,
  Rect,
  RegularPolygon,
  Stage,
  Star,
  Text,
  Transformer,
} from "react-konva";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Input, Tooltip, Whisper } from "rsuite";
import DashboardLayout from "../components/dashboard";
import InlineForm from "../components/inline_form";
import { MerchantRoom } from "../model/merchant";
import { Shape, ShapeType } from "../model/shape";
import { LoadingContext } from "../objects/loading_context";
import { getPosRoomDetail, updatePosRoomDetail } from "../repositories/pos";
import { randomStr, setNullString } from "../utils/helper";
import { errorToast, successToast } from "../utils/helper-ui";
import { RenderShape } from "./RenderShape";

const RoomEditor: React.FC = () => {
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedShape, setSelectedShape] = useState<Shape | null>(null);
  const stageCanvasRef = useRef<HTMLDivElement>(null);
  const layeRef = useRef<Konva.Layer>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [mounted, setMounted] = useState(false);
  const { roomId } = useParams();
  const [room, setRoom] = useState<MerchantRoom>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [showGrid, setShowGrid] = useState(true);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const onBeforeUnload = (ev: any) => {
      console.log("SOME CODE HERE");

      ev.returnValue = "Anything you wanna put here!";
      return "Anything here as well, doesn't matter!";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!mounted) return;
    setIsLoading(true);
    getPosRoomDetail(roomId!)
      .then((v) => v.json())
      .then((res) => {
        setRoom(res.data);
      })
      .catch(errorToast)
      .finally(() => {
        setIsLoading(false);
      });
  }, [mounted, roomId]);

  useEffect(() => {
    if (room) {
      // console.log(room.tables)
      setShapes(room.tables);
    }
  }, [room]);

  useLayoutEffect(() => {
    if (stageCanvasRef?.current) {
      setWidth(stageCanvasRef?.current?.clientWidth);
      setHeight(stageCanvasRef?.current?.clientHeight);
    }
  }, [shapes]);
  const addShape = (type: ShapeType) => {
    const newShape: Shape = (() => {
      switch (type) {
        case "circle":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            text: `cicle #${shapes.length + 1}`,
            x: 100,
            y: 100,
            width: 100,
            height: 100,
            radius: 30,
            fill: "#dedede",
            stroke: "#333333",
          };
        case "rect":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            text: `rect #${shapes.length + 1}`,
            x: 100,
            y: 100,
            width: 100,
            height: 50,
            fill: "#dedede",
            stroke: "#333333",
          };
        case "ellipse":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            text: `ellipse #${shapes.length + 1}`,
            x: 150,
            y: 150,
            width: 200,
            height: 200,
            fill: "#dedede",
            stroke: "#333333",
          };
        case "polygon":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            text: `polygon #${shapes.length + 1}`,
            type,
            x: 150,
            y: 150,
            radius: 50,
            sides: 3,
            fill: "#dedede",
            stroke: "#333333",
          };
        case "line":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            x: 50,
            y: 50,
            points: [0, 0, 100, 100],
            fill: "#dedede",
            stroke: "#333333",
          };
        case "text":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            x: 200,
            y: 200,
            text: "Hello!",
            fontSize: 20,
            fill: "#dedede",
            stroke: "#333333",
          };
        case "star":
          return {
            id: randomStr(6),
            strokeWidth: 0.5,
            type,
            x: 300,
            y: 300,
            radius: 50,
            sides: 5,
            fill: "#dedede",
            stroke: "#333333",
          };
        default:
          throw new Error("Unsupported shape type");
      }
    })();
    setShapes([...shapes, newShape]);
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmptyArea = e.target === e.target.getStage();
    if (clickedOnEmptyArea) {
      setSelectedId(null);
      setSelectedShape(null);
    } else {
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <div
          style={{ marginBottom: "10px" }}
          className=" flex flex-row justify-between items-center"
        >
          <div className="flex flex-row items-center gap-2">
            <div className="bg-white p-2 rounded flex flex-row">
              <h3 className="text-xl">{room?.name}</h3>
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Rectangle</Tooltip>}
              >
                <button className="mx-8" onClick={() => addShape("rect")}>
                  <PiRectangleLight />
                </button>
              </Whisper>

              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Ellipse</Tooltip>}
              >
                <button className="mx-8" onClick={() => addShape("ellipse")}>
                  <img
                    src="/images/ellipse-outline-shape-variant.png"
                    alt=""
                    className="h-4"
                  />
                </button>
              </Whisper>

              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Polygon</Tooltip>}
              >
                <button className="mx-8" onClick={() => addShape("polygon")}>
                  <BiPolygon />
                </button>
              </Whisper>

              <Checkbox checked={showGrid} onChange={(val) => setShowGrid(!showGrid)}>Grid</Checkbox>
            </div>
          </div>
          {/* <button className="mx-8" onClick={() => addShape("star")}>+ Star</button> */}
          <Button
            appearance="primary"
            onClick={() => {
              var req = {
                ...room,
                uuid: setNullString(room?.uuid),
                tables: JSON.stringify(shapes),
              };
              // console.log(shapes);
              setIsLoading(true);
              updatePosRoomDetail(room?.uuid ?? "", req)
                .then((v) => v.json())
                .then((v) => {
                  successToast("Data berhasil disimpan");
                })
                .catch(errorToast)
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <FaFloppyDisk className="mr-2" />
            Simpan
          </Button>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3" ref={stageCanvasRef}>
            <Stage
              width={width ?? 800}
              height={height ?? 600}
              style={{ border: "1px solid #dedede", background: "white" }}
              onClick={checkDeselect}
            >
              <Layer ref={layeRef}>
                {/* Grid guide lines */}
                {showGrid && Array.from({ length: 40 }).map((_, index) => (
                  <Line
                    key={`grid-line-${index}`}
                    points={[index * 40, 0, index * 40, height ?? 600]}
                    stroke="#e0e0e0"
                    strokeWidth={1}
                  />
                ))}
                {showGrid && Array.from({ length: 40 }).map((_, index) => (
                  <Line
                    key={`grid-line-horizontal-${index}`}
                    points={[0, index * 40, width ?? 800, index * 40]}
                    stroke="#e0e0e0"
                    strokeWidth={1}
                  />
                ))}
                {shapes.map((shape, i) => {
                  return (
                    <RenderShape
                      key={i}
                      isEditable={true}
                      width={width ?? 800}
                      height={height ?? 600}
                      isSelected={selectedId == shape.id}
                      shape={shape}
                      onTap={(shape) => {
                        setSelectedId(shape.id);
                        setSelectedShape(shape);
                      }}
                      onChange={(shape) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === shape.id) {
                            setSelectedShape(shape);
                            return shape;
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  );
                })}
              </Layer>
            </Stage>
          </div>
          <div className="col-span-1 bg-white">
            {selectedShape && (
              <>
                <div className="p-4">
                  <InlineForm title={"Text"}>
                    <Input
                      type="text"
                      value={selectedShape.text}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              text: e!,
                            });
                            return {
                              ...s,
                              text: e!,
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>

                  <InlineForm title={"Warna"}>
                    <input
                      type="color"
                      value={selectedShape.fill}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              fill: e.target.value,
                            });
                            return {
                              ...s,
                              fill: e.target.value,
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>
                  <InlineForm title={"Warna Text"}>
                    <input
                      type="color"
                      value={selectedShape.textColor}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              textColor: e.target.value,
                            });
                            return {
                              ...s,
                              textColor: e.target.value,
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>
                  <InlineForm title={"Garis"}>
                    <input
                      type="color"
                      value={selectedShape.stroke}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              stroke: e.target.value,
                            });
                            return {
                              ...s,
                              stroke: e.target.value,
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>
                  <InlineForm title={"Tebal Garis"}>
                    <Input
                      type="number"
                      value={selectedShape.strokeWidth}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              strokeWidth: Number(e),
                            });
                            return {
                              ...s,
                              strokeWidth: Number(e),
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>
                  {selectedShape.type == "rect" && (
                    <InlineForm title={"Corner"}>
                      <Input
                        type="number"
                        value={selectedShape.cornerRadius}
                        onChange={(e) => {
                          const newShapes = shapes.map((s) => {
                            if (s.id === selectedShape.id) {
                              setSelectedShape({
                                ...s,
                                cornerRadius: Number(e),
                              });
                              return {
                                ...s,
                                cornerRadius: Number(e),
                              };
                            }
                            return s;
                          });
                          setShapes(newShapes);
                        }}
                      />
                    </InlineForm>
                  )}
                  <InlineForm title={"Rotasi"}>
                    <Input
                      type="number"
                      value={selectedShape.rotation}
                      onChange={(e) => {
                        const newShapes = shapes.map((s) => {
                          if (s.id === selectedShape.id) {
                            setSelectedShape({
                              ...s,
                              rotation: Number(e),
                            });
                            return {
                              ...s,
                              rotation: Number(e),
                            };
                          }
                          return s;
                        });
                        setShapes(newShapes);
                      }}
                    />
                  </InlineForm>
                  {selectedShape.type == "polygon" && (
                    <InlineForm title={"Side"}>
                      <Input
                        type="number"
                        min={3}
                        value={selectedShape.sides}
                        onChange={(e) => {
                          const newShapes = shapes.map((s) => {
                            if (s.id === selectedShape.id) {
                              setSelectedShape({
                                ...s,
                                sides: Number(e),
                              });
                              return {
                                ...s,
                                sides: Number(e),
                              };
                            }
                            return s;
                          });
                          setShapes(newShapes);
                        }}
                      />
                    </InlineForm>
                  )}
                  <InlineForm title={"Tool"}>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Naikan</Tooltip>}
                    >
                      <Button
                        onClick={() => {
                          const index = shapes.findIndex(
                            (s) => s.id === selectedShape.id
                          );
                          if (index < shapes.length - 1) {
                            const newShapes = [...shapes];
                            const [selected] = newShapes.splice(index, 1);
                            newShapes.splice(index + 1, 0, selected);
                            setShapes(newShapes);
                          }
                        }}
                      >
                        <RiBringForward />
                      </Button>
                    </Whisper>
                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={<Tooltip>Turunkan</Tooltip>}
                    >
                      <Button
                        onClick={() => {
                          const index = shapes.findIndex(
                            (s) => s.id === selectedShape.id
                          );
                          if (index > 0) {
                            const newShapes = [...shapes];
                            const [selected] = newShapes.splice(index, 1);
                            newShapes.splice(index - 1, 0, selected);
                            setShapes(newShapes);
                          }
                        }}
                      >
                        <RiSendToBack />
                      </Button>
                    </Whisper>
                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={<Tooltip>Duplikat</Tooltip>}
                    >
                      <Button
                        onClick={() => {
                          const newShape = {
                            ...selectedShape,
                            id: randomStr(6),
                            text: `copy of ${selectedShape.text}`,
                            x: selectedShape.x + 20,
                            y: selectedShape.y + 20,
                          };
                          setShapes([...shapes, newShape]);
                          setSelectedShape(newShape);
                        }}
                      >
                        <BiCopy />
                      </Button>
                    </Whisper>
                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={<Tooltip>Hapus</Tooltip>}
                    >
                      <Button
                        onClick={() => {
                          setShapes(
                            shapes.filter((s) => s.id !== selectedShape.id)
                          );
                          setSelectedShape(null);
                        }}
                      >
                        <BiTrash />
                      </Button>
                    </Whisper>
                  </InlineForm>

                  {/* <InlineForm title={"Ukuran"}>
                    {selectedShape?.width} x {selectedShape?.height}
                  </InlineForm> */}
                  {/* <InlineForm title={"Posisi"}>
                    {selectedShape?.x} x {selectedShape?.y}
                  </InlineForm> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RoomEditor;
