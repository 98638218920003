import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import CashierPage from '../pages/CashierPage';
import CashierReportPrint from '../pages/CashierReportPrint';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import CashierReport from '../pages/CashierReport';
import PosReportReceipt from '../pages/PosReportReceipt';
import SettingPage from '../pages/SettingPage';
import RoomEditor from '../pages/RoomEditor';

interface PrivateRoutesProps {}

const PrivateRoutes: FC<PrivateRoutesProps> = ({}) => {
        return (<Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cashier" element={<CashierPage />} />
            <Route path="/cashier/report/:cashierReportId/print" element={<CashierReportPrint />} />
            <Route path="/cashier/report" element={<CashierReport />} />
            <Route path="/pos/:receiptId/receipt" element={<PosReportReceipt />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/room/:roomId" element={<RoomEditor />} />

            <Route path="*" element={<NotFound />} />


        </Routes>);
}
export default PrivateRoutes;