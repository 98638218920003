import { useContext, useEffect, useRef, useState, type FC } from "react";
import { Machine, Merchant } from "../model/merchant";
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  QrCodeIcon,
  TrashIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import {
  AvatarGroup,
  Badge,
  Drawer,
  Dropdown,
  Message,
  Tooltip,
  useToaster,
  Whisper,
  Avatar as RsAvatar,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { Profile } from "../model/profile";
import {
  PriceLevelData,
  Product,
  ProductCategory,
  ProductStock,
  ProductStoreStock,
  VarianStock,
} from "../model/product";
import {
  MerchantSale,
  MerchantSaleItem,
  merchantSaleToReq,
} from "../model/pos";
import { Tax } from "../model/tax";
import { Customer } from "../model/customer";
import { Discount } from "../model/discount";
import {
  DINE_IN,
  ORDER_TYPE,
  ORDER_TYPE_LABEL,
  OrderType,
} from "../objects/constants";
import {
  getPosDiscounts,
  getPosProductByBarcode,
  getPosProductCategories,
  getPosProducts,
  getPosSaleDetail,
  sendPosSale,
} from "../repositories/pos";
import { getCirculationStock } from "../repositories/circulation";
import { getTaxDetail } from "../repositories/tax";
import {
  getProfileStorage,
  getStorageMachine,
  parseVarian,
  randomStr,
  reverseArr,
  setStorageMachine,
} from "../utils/helper";
import {
  getMerchantDetail,
  getMerchantMachines,
} from "../repositories/merchant";
import { errorToast } from "../utils/helper-ui";
import { calculateTax, countDiscount, countItem, countSubTotal, countTax, delSale, getSales, saveSale } from "../utils/pos_helper";
import moment from "moment";
import Swal from "sweetalert2";
import { getProductStocks } from "../repositories/product";
import { money } from "../utils/number";
import { FaRegFloppyDisk } from "react-icons/fa6";
import CurrencyInput from "react-currency-input-field";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import ModalSearch from "./modals/modal_search";
import Avatar from "./avatar";
import ModalCustomer from "./modals/model_customer";
import { paymentMethodsData, paymentProvidersData } from "../objects/pos";
import ModalCustom from "./modals/modal_custom";
import ModalReceipt from "./modals/modal_receipt";
import OrderProduct from "./OrderProduct";
import OrderItems from "./OrderItems";

interface RegularFlowProps {
  merchant: Merchant;
}

const RegularFlow: FC<RegularFlowProps> = ({ merchant }) => {
  const nav = useNavigate();
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const toaster = useToaster();
  const [categoryId, setCategoryId] = useState("");
  const [mounted, setMounted] = useState(false);

  const [products, setProducts] = useState<Product[]>([]);

  const [salesItems, setSalesItems] = useState<MerchantSaleItem[]>([]);
  const [mountQty, setMountQty] = useState(true);
  const [searchProducts, setSearchProducts] = useState<Product[]>([]);
  const [search, setSearch] = useState("");
  const [inputQty, setInputQty] = useState(0);
  const [modalVarianOpen, setModalVarianOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedVarians, setSelectedVarians] = useState<VarianStock[]>([]);
  const [varianStocks, setVarianStocks] = useState<ProductStoreStock[]>([]);
  const [tax, setTax] = useState<Tax | null>(null);
  const [sidebarPaymentOpen, setSidebarPaymentOpen] = useState(false);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [paymentProviderGroup, setPaymentProviderGroup] = useState("");
  const [paymentProvider, setPaymentProvider] = useState("CASH");
  const [amountPayment, setAmountPayment] = useState(0);
  const [changes, setChanges] = useState(0);
  const [mountPayment, setMountPayment] = useState(true);
  const [inputCustomerName, setInputCustomerName] = useState("");
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [modalCustomerOpen, setModalCustomerOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([]);
  const [savedSales, setSavedSales] = useState<MerchantSale[]>([]);
  const [drawerSaved, setDrawerSaved] = useState(false);

  const [editedSale, setEditedSale] = useState<MerchantSale | null>(null);

  const [orderType, setOrderType] = useState<string>(DINE_IN);
  const [selectedOrderType, setSelectedOrderType] = useState<OrderType>({
    name: DINE_IN,
    iconType: "ionicons",
    icon: "fast-food-outline",
    providers: ["TABLE", "FREE_TABLE"],
  });
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [inputOrderName, setInputOrderName] = useState("");
  const [modalReceiptOpen, setModalReceiptOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState<MerchantSale | null>(
    null
  );

  useEffect(() => {
    setMounted(true);
    setSelectedProvider("TABLE");
  }, []);

  useEffect(() => {
    if (
      selectedProduct &&
      merchant &&
      merchant.default_store_id &&
      merchant.check_stock
    ) {
      getCirculationStock(selectedProduct.uuid, merchant!.default_store_id!)
        .then((v) => v.json())
        .then((v) => setVarianStocks(v.data));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (merchant && merchant.tax_id) {
      getTaxDetail(merchant.tax_id)
        .then((v) => v.json())
        .then((v) => setTax(v.data));
    }
  }, [merchant]);

  useEffect(() => {
    if (!mounted) return;
    initData();
  }, [mounted]);

  const initData = async () => {
    setProfile(await getProfileStorage());

    getMerchantMachines()
      .then((v) => v.json())
      .then((v) => setMachines(v.data))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
    setSelectedMachine(await getStorageMachine());
    getPosDiscounts()
      .then((v) => v.json())
      .then((v) => setDiscounts(v.data))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
    getSales()
      .then((v) => setSavedSales(v))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
  };

  

  const addItem = (
    item: Product,
    varian?: VarianStock | null,
    discount?: Discount | null
  ) => {
    let itemExist = salesItems.find((e) => e.product.uuid == item.uuid);
    if (item.varians.length > 0 && varian != null) {
      itemExist = salesItems.find(
        (e) => e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid
      );
    }
    let qty = 1;

    if (!itemExist) {
      let saleItem: MerchantSaleItem = {
        uniqueId: randomStr(3),
        product: item,
        qty: qty,
        discount: discount,
        discount_id: discount?.uuid,
        isService: false,
        price: item.prices[0].amount,
        varian: varian,
        tax_id: tax?.uuid,
        tax: tax,
      };
      salesItems.push(countItem(saleItem, discount, merchant, tax));

      setSalesItems([...salesItems]);
    } else {
      let newItems = salesItems.map((e) => {
        if (item.varians.length > 0 && varian != null) {
          if (e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid) {
            e.qty++;
            e = countItem(e, discount, merchant, tax);
          }
        } else {
          if (e.product.uuid == item.uuid) {
            e.qty++;
            e = countItem(e, discount, merchant, tax);
          }
        }

        return e;
      });

      setSalesItems([...newItems]);
    }

    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 10);
  };

  const countGrandTotal = (): number => {
    let result = calculateTax(
      countSubTotal(salesItems) - countDiscount(salesItems),
      merchant?.price_tax_include ?? false,
      tax,
    );
    return result.priceAfterTax;
  };

  
  const sendSale = async () => {
    try {
      setIsLoading(true);
      let selProvider = merchant!.payment_providers.find(
        (e) => e.provider == paymentProvider
      );
      let dataSales: MerchantSale = {
        code: editedSale ? editedSale.code : randomStr(5).toUpperCase(),
        items: salesItems,
        date: moment().toISOString(),
        cashier_id: profile!.uuid,
        machine_id: selectedMachine!.uuid,
        cashier_name: profile!.full_name,
        customer_id: customer?.uuid ?? "",
        customer_name: inputCustomerName,
        notes: notes,
        subtotal: countSubTotal(salesItems),
        discount_total: countDiscount(salesItems),
        total: countSubTotal(salesItems) - countDiscount(salesItems),
        tax_amount: countTax(salesItems, merchant, tax),
        total_tax: countTax(salesItems, merchant, tax),
        tax_name: tax?.name ?? "",
        count_item: salesItems.length,
        grand_total: countGrandTotal(),
        change: amountPayment - countGrandTotal(),
        payment_amount: amountPayment,
        payment_method: paymentMethod,
        payment_provider: paymentProvider,
        account_destination_id: selProvider!.account_destination_id!,
        order_name: inputOrderName,
        order_type_provider: selectedProvider,
        order_type: orderType,
      };

      var resp = await sendPosSale(merchantSaleToReq(dataSales));
      var respJson = await resp.json();
      getPosSaleDetail(respJson.data.last_id)
        .then((v) => v.json())
        .then((v) => {
          setSelectedReceipt(v.data);
          setModalReceiptOpen(true);
        });
      // window.open(`/pos/${respJson.data.last_id}/receipt`)
      clearForm();
      if (editedSale) {
        delSale(editedSale).then((v) => setSavedSales(v));
      }
      setEditedSale(null);
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setSalesItems([]);
    setModalVarianOpen(false);
    setSelectedProduct(null);
    setSelectedVarians([]);
    setVarianStocks([]);
    setPaymentMethod("CASH");
    setPaymentProviderGroup("");
    setPaymentProvider("CASH");
    setAmountPayment(0);
    setInputCustomerName("");
    setCustomer(null);
    setModalCustomerOpen(false);
    setAmountPayment(0);
    setSidebarPaymentOpen(false);
  };

  const checkDiscounts = (item: MerchantSaleItem): Discount[] => {
    return discounts.filter(
      (e) => {
        if (!item.product) return false
        let isAllProduct = e.min_qty <= item.qty && e.max_qty >= item.qty && e.products.map(e => e.uuid).includes(item.product?.uuid!)
        let isAllCustomer = e.is_all_customer ? true : e.min_qty <= item.qty && e.max_qty >= item.qty && (customer && e.customers.map(e => e.uuid).includes(customer.uuid))

        return isAllProduct && isAllCustomer
      }
    );
  };
  const editItem = (
    item: MerchantSaleItem,
    qty: number,
    refresh?: boolean,
    discount?: Discount | null
  ) => {
    let newItems = salesItems.map((e) => {
      if (item.uniqueId == e.uniqueId) {
        if (item.product.prices[0].price_level_data.length > 0) {
          let levels: PriceLevelData[] = reverseArr(
            item.product.prices[0].price_level_data
          );
          for (const key in levels) {
            if (Object.prototype.hasOwnProperty.call(levels, key)) {
              const element = levels[key];
              if (qty >= element.min) {
                e.price = element.amount;
                // console.log(element.min)
                break;
              }
            }
          }
        }
        e.qty = qty;
        e = countItem(e, discount);
      }
      return e;
    });
    setSalesItems([...newItems]);
    if (refresh) {
      setMountQty(false);
      setTimeout(() => {
        setMountQty(true);
      }, 10);
    }
  };

  const delItem = (item: MerchantSaleItem) => {
    let newItems = salesItems.filter((e) => e.uniqueId != item.uniqueId);
    setSalesItems([...newItems]);
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 10);
  };

  return (
    <>
      <div className="flex h-full " style={{ width: "calc(100vw - 100px)" }}>
        <div className=" w-9/12 flex flex-col">
          <OrderProduct merchant={merchant} addItem={addItem} />
        </div>
        <div className=" w-3/12 h-full bg-white border-l border-l-gray-200 p-4 flex flex-col">
          <div className=" flex justify-between items-center">
            {/* <h3 className=' xl:text-2xl md:text-lg font-semibold mb-2 '>Order Detail</h3> */}
            <div className="flex mb-2 justify-between items-center w-full gap-4">
              <Dropdown
                title={ORDER_TYPE_LABEL[orderType]}
                placement="bottomEnd"
              >
                {ORDER_TYPE.map((e) => (
                  <Dropdown.Item
                    key={e.name}
                    onClick={() => {
                      setOrderType(e.name);
                      setSelectedOrderType(e);
                      if (e.providers.length > 0) {
                        setSelectedProvider(e.providers[0]);
                      } else {
                        setSelectedProvider(null);
                      }
                    }}
                  >
                    {ORDER_TYPE_LABEL[e.name]}
                  </Dropdown.Item>
                ))}
              </Dropdown>
              <div className="flex gap-2 flex-row items-center">
                <Dropdown
                  title={selectedProvider ?? "Layanan"}
                  placement="bottomEnd"
                >
                  {selectedOrderType.providers.map((e) => (
                    <Dropdown.Item
                      key={e}
                      onClick={() => {
                        setSelectedProvider(e);
                      }}
                    >
                      {e}
                    </Dropdown.Item>
                  ))}
                </Dropdown>

                <Whisper
                  followCursor
                  placement="left"
                  speaker={<Tooltip>Transaksi Tersimpan</Tooltip>}
                >
                  <div>
                    {savedSales.length == 0 ? (
                      <FaRegFloppyDisk /// <reference path="" />
                        size={24}
                        className="ml-2 text-gray-400 cursor-pointer hover:text-gray-600"
                        onClick={() => setDrawerSaved(true)}
                      />
                    ) : (
                      <Badge content={savedSales.length}>
                        <FaRegFloppyDisk /// <reference path="" />
                          size={24}
                          className="ml-2 text-gray-400 cursor-pointer hover:text-gray-600"
                          onClick={() => setDrawerSaved(true)}
                        />
                      </Badge>
                    )}
                  </div>
                </Whisper>
              </div>
              {/* <Whisper followCursor placement='left' speaker={<Tooltip>Laporan Penjualan</Tooltip>}>
                            <div>
                                <FaChartLine size={24} className='ml-2 text-gray-400 cursor-pointer hover:text-gray-600' onClick={() => nav(`/cashier/report`)} />
                            </div>
                        </Whisper> */}
            </div>
          </div>
          <OrderItems
            merchant={merchant}
            salesItems={salesItems}
            editItem={editItem}
            delItem={delItem}
            checkDiscounts={checkDiscounts}
          />
          <div className=" bg-gray-100 rounded-lg px-3 py-2 flex items-center">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <p className=" text-gray-600">Subtotal</p>
                <h1 className=" text-lg">{money(countSubTotal(salesItems))}</h1>
              </div>
              {countDiscount(salesItems) > 0 && (
                <div className="flex justify-between items-center">
                  <p className=" text-gray-600">Total Diskon</p>
                  <h1 className=" text-lg">{money(countDiscount(salesItems))}</h1>
                </div>
              )}

              {merchant != null && tax && (
                <div className="flex justify-between items-center">
                  <p className=" text-gray-600">
                    {tax.name} ({tax.amount}%)
                  </p>
                  <h1 className=" text-lg">{money(countTax(salesItems, merchant, tax))}</h1>
                </div>
              )}
              <div className="flex justify-between items-center">
                <p className=" text-gray-600">Total</p>
                <h1 className=" text-2xl font-semibold text-red-600">
                  {money(countGrandTotal())}
                </h1>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-2">
            <button
              onClick={() => {
                if (salesItems.length == 0) {
                  Swal.fire("Perhatian", `Belum ada item`, "warning");
                  return;
                }
                setSidebarPaymentOpen(true);
                setAmountPayment(countGrandTotal());
              }}
              className="h-12 flex-1 text-white flex justify-center items-center bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-base  px-5 py-2.5 text-center "
            >
              <FaRegMoneyBillAlt className="w-8 mr-2 " /> Pembayaran
            </button>
            <button
              onClick={() => {
                saveSale({
                  code: randomStr(5).toUpperCase(),
                  items: salesItems,
                  date: moment().toISOString(),
                  cashier_id: profile!.uuid,
                  machine_id: selectedMachine!.uuid,
                  cashier_name: profile!.full_name,
                  customer_id: customer?.uuid ?? "",
                  customer_name: inputCustomerName,
                  notes: notes,
                  subtotal: countSubTotal(salesItems),
                  discount_total: countDiscount(salesItems),
                  total: countSubTotal(salesItems) - countDiscount(salesItems),
                  tax_amount: countTax(salesItems, merchant, tax),
                  total_tax: countTax(salesItems, merchant, tax),
                  tax_name: tax?.name ?? "",
                  count_item: salesItems.length,
                  grand_total: countGrandTotal(),
                  change: amountPayment - countGrandTotal(),
                  payment_amount: amountPayment,
                  payment_method: paymentMethod,
                  payment_provider: paymentProvider,
                  account_destination_id: "",
                  order_name: inputOrderName,
                  order_type: orderType,
                  order_type_provider: selectedProvider,
                }).then((val) => {
                  setSavedSales(val);
                  Swal.fire("Perhatian", `Transaksi Tersimpan`, "success");
                  clearForm();
                });
              }}
              className="h-12 ml-2 text-white text-sm  flex flex-col justify-center items-center bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-2.5 py-4   text-center "
            >
              <Whisper
                followCursor
                placement="left"
                speaker={<Tooltip>Simpan Transaksi</Tooltip>}
              >
                {/* <FolderIcon className='w-6 ' /> */}
                <div className="flex items-center">
                  <FaRegFloppyDisk className="w-8 " /> Simpan
                </div>
              </Whisper>
            </button>
          </div>
        </div>
      </div>

      <ModalCustomer
        isOpen={modalCustomerOpen}
        setIsOpen={setModalCustomerOpen}
        onClick={(val) => {
          setInputCustomerName(val.name);
          setCustomer(val);
        }}
      />
      <Drawer
        size={"sm"}
        placement={"right"}
        open={drawerSaved}
        onClose={() => setDrawerSaved(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Tersimpan</Drawer.Title>
          <Drawer.Actions></Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body className="p-6">
          <ul>
            {savedSales.map((e) => (
              <li
                className="flex  px-2  py-4 border-b last:border-b-0 hover:bg-gray-50 cursor-pointer"
                key={e.code}
              >
                <div
                  className="flex-1 flex justify-between"
                  onClick={() => {
                    setSalesItems(e.items);
                    setEditedSale(e);
                    setDrawerSaved(false);
                    setOrderType(e.order_type ?? DINE_IN);
                    setSelectedProvider(e.order_type_provider ?? null);
                  }}
                >
                  <AvatarGroup stack>
                    {e.items
                      .filter((item, i) => i < 3)
                      .map((item) => (
                        <Whisper
                          followCursor
                          speaker={
                            <Tooltip>{`${item.product.name} ${
                              item.varian
                                ? parseVarian(item.varian.data, true)
                                : ""
                            }`}</Tooltip>
                          }
                        >
                          <RsAvatar
                            bordered
                            circle
                            key={item.product.name}
                            src={
                              item.product.default_picture
                                ? item.product.default_picture
                                : "/images/logo-niaga.png"
                            }
                            alt={item.product.name}
                          />
                        </Whisper>
                      ))}
                    {e.items.length - 3 > 0 && (
                      <RsAvatar bordered circle style={{ background: "#111" }}>
                        +{e.items.length - 3}
                      </RsAvatar>
                    )}
                  </AvatarGroup>
                  <div className="flex flex-col  items-end">
                    <h3 className=" text-lg text-orange-600 font-semibold">
                      {money(e.grand_total)}
                    </h3>
                    <span>{e.items.length} items</span>
                  </div>
                </div>
                <TrashIcon
                  onClick={() => {
                    Swal.fire({
                      title: "Perhatian",
                      text: "Anda yakin akan menghapus transaksi ini ",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ya, Kirim!",
                      cancelButtonText: "Batal",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        delSale(e).then((v) => setSavedSales(v));
                      }
                    });
                  }}
                  className="ml-2 w-5 text-red-500"
                />
              </li>
            ))}
          </ul>
        </Drawer.Body>
      </Drawer>

      <Drawer
        size={"md"}
        placement={"right"}
        open={sidebarPaymentOpen}
        onClose={() => setSidebarPaymentOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>
            <div className=" flex m-0 justify-between items-center">
              <p>Proses Pembayaran</p>
              <p
                className="flex m-0 text-4xl font-semibold text-orange-600 cursor-pointer"
                onClick={() => {
                  setAmountPayment(countGrandTotal());
                  setMountPayment(false);
                  setTimeout(() => {
                    setMountPayment(true);
                  }, 10);
                }}
              >
                {money(countGrandTotal())}
              </p>
            </div>
          </Drawer.Title>
          <Drawer.Actions>
            {/* <Button onClick={() => setSidebarPaymentOpen(false)}>Cancel</Button>
                    <Button onClick={() => setSidebarPaymentOpen(false)} appearance="primary">
                        Confirm
                    </Button> */}
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body className="p-6 flex flex-col ">
          <div className="flex-1">
            <div className="grid gap-x-4 gap-y-4 grid-cols-2">
              <div>
                <h3 className="text-lg mb-2">Konsumen</h3>
                <div className="relative">
                  <input
                    placeholder="Nama Konsumen"
                    value={inputCustomerName}
                    onChange={(val) => {
                      setInputCustomerName(val.target.value);
                      if (val.target.value == "") {
                        setCustomer(null);
                      }
                    }}
                    className="bg-white mb-4  text-lg w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  />
                  <div className="absolute right-2 top-2.5 cursor-pointer">
                    <MagnifyingGlassIcon
                      className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                      onClick={() => setModalCustomerOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-lg mb-2">Nama Order</h3>
                <div className="relative">
                  <input
                    placeholder="Nama Order"
                    value={inputOrderName}
                    onChange={(val) => {
                      setInputOrderName(val.target.value);
                    }}
                    className="bg-white mb-4  text-lg w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  />
                  <div className="absolute right-2 top-2.5 cursor-pointer"></div>
                </div>
              </div>
            </div>

            <h3 className="text-lg m-0">Pilih Cash Register</h3>
            <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
              {machines.map((e) => (
                <div
                  onClick={() => {
                    setSelectedMachine(e);
                    setStorageMachine(e);
                  }}
                  key={e.uuid}
                  className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                    e.uuid == selectedMachine?.uuid
                      ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                      : "bg-gray-50"
                  }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                >
                  {e.name}
                </div>
              ))}
            </div>
            <h3 className="text-lg m-0">Pilih Pembayaran</h3>
            <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
              {paymentMethodsData.map((e) => (
                <div
                  onClick={() => {
                    setPaymentMethod(e.value);
                    if (e.value == "CASH") {
                      setPaymentProviderGroup("");
                      setPaymentProvider("CASH");
                    }
                  }}
                  key={e.value}
                  className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                    e.value == paymentMethod
                      ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                      : "bg-gray-50"
                  }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                >
                  {e.name}
                </div>
              ))}
            </div>
            {paymentMethod != "CASH" && (
              <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
                {paymentProvidersData.map((e) => (
                  <div
                    onClick={() => {
                      setPaymentProviderGroup(e.name);
                      setPaymentProvider("");
                    }}
                    key={e.name}
                    className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                      e.name == paymentProviderGroup
                        ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                        : "bg-gray-50"
                    }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            )}
            {paymentMethod != "CASH" && paymentProviderGroup != "" && (
              <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
                {paymentProvidersData
                  .find((e) => e.name == paymentProviderGroup)!
                  .children.map((e) => (
                    <div
                      onClick={() => {
                        setPaymentProvider(e.value);
                      }}
                      key={e.name}
                      className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                        e.value == paymentProvider
                          ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                          : "bg-gray-50"
                      }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                    >
                      {e.name}
                    </div>
                  ))}
              </div>
            )}
            {paymentMethod == "CASH" && (
              <h3 className="text-lg m-0 text-right">Jumlah</h3>
            )}
            {paymentMethod == "CASH" && mountPayment && (
              <CurrencyInput
                className="bg-white mb-4  text-4xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                groupSeparator="."
                decimalSeparator=","
                placeholder="Jumlah"
                defaultValue={Math.round(countGrandTotal())}
                decimalsLimit={0}
                onValueChange={(value, _, values) => {
                  setAmountPayment(values!.float!);
                }}
              />
            )}
            {paymentMethod == "CASH" &&
              amountPayment - countGrandTotal() != 0 && (
                <h3 className="text-lg m-0  text-right">Kembalian</h3>
              )}
            {paymentMethod == "CASH" &&
              amountPayment - countGrandTotal() != 0 && (
                <h3 className="text-2xl m-0 text-orange-600 text-right">
                  {money(amountPayment - countGrandTotal())}
                </h3>
              )}
            <h3 className="text-lg m-0 text-right">Catatan</h3>
            <textarea
              placeholder="Catatan"
              value={notes}
              rows={3}
              onChange={(val) => setNotes(val.target.value)}
              className="bg-white mb-4  text-xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={sendSale}
              className=" flex-1 text-white flex justify-center items-center bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-base  px-5 py-2.5 text-center "
            >
              <FaRegMoneyBillAlt className="w-8 mr-2 " /> Proses Pembayaran
            </button>
          </div>
        </Drawer.Body>
      </Drawer>

      <ModalReceipt
        isOpen={modalReceiptOpen}
        setIsOpen={setModalReceiptOpen}
        onClose={() => setModalReceiptOpen(false)}
        sale={selectedReceipt}
        merchant={merchant!}
      />
    </>
  );
};
export default RegularFlow;
