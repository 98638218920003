import React, { Suspense, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppRoute } from './routes/AppRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CustomProvider } from 'rsuite';
import { LoadingContext } from './objects/loading_context';
import idID from "./objects/id_ID";
import 'rsuite/dist/rsuite-no-reset.min.css';


function App() {
  const [mounted, setMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [splash, setSplash] = useState<boolean>(true);

  useEffect(() => {
    
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
  });
  }, []);
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_CLIENT_API!}  >
      <Suspense>
        <CustomProvider locale={idID}>
          <LoadingContext.Provider value={{ isLoading: loading, setIsLoading: setLoading }}>
            <AppRoute />
          </LoadingContext.Provider>
        </CustomProvider>
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
