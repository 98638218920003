import { ComponentProps, ReactElement, useEffect, useState, type FC } from 'react';
import ModalCustom from './modal_custom';
import { MerchantSale } from '../../model/pos';
import { Br, Cut, Line, Printer, Row, Text, render, Image } from 'react-thermal-printer';
import { errorToast } from '../../utils/helper-ui';
import { randomStr, sleep } from '../../utils/helper';
import { Merchant } from '../../model/merchant';
import moment from 'moment';
import { money } from '../../utils/number';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { IoPrintOutline } from "react-icons/io5";
import { StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { transforms } from '@react-thermal-printer/image';


interface ModalReceiptProps {
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onClose: () => void
    sale?: MerchantSale | null
    merchant?: Merchant | null
}

const ModalReceipt: FC<ModalReceiptProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    sale,
    merchant
}) => {
    const [qrString, setQrString] = useState("");
    useEffect(() => {
    
        if (merchant && merchant.message_to_qr) {
            getQRMessage(merchant.message_to_qr)
                .then(e => {
                    setQrString(e)
                })
        }
    }, [merchant]);

    
    const printReceipt = async () => {
        try {
            let mobileNavigatorObject: any = window.navigator;


            if (mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
                const device = await mobileNavigatorObject.bluetooth.requestDevice({
                    filters: [
                        { services: ['000018f0-0000-1000-8000-00805f9b34fb'] },
                    ],
                })

                const server = await device.gatt.connect();
                const service = await server.getPrimaryService(
                    "000018f0-0000-1000-8000-00805f9b34fb"
                );

                const characteristic = await service.getCharacteristic(
                    "00002af1-0000-1000-8000-00805f9b34fb"
                );
                const receipt = (
                    <Printer type="epson" width={32} debug={true}>
                        <Text size={{ width: 2, height: 2 }}>9,500</Text>
                        <Br />
                        <Br />
                        <Br />
                        <Br />
                    </Printer>
                );

                const bytes = await renderReceiptData(sale!, merchant!, 32);
                // characteristic.writeValue(data)

                const MAX_LENGTH = 64;
                for (let i = 0; i < bytes.byteLength; i += MAX_LENGTH) {
                    const end = (i + MAX_LENGTH > bytes.byteLength) ? bytes.byteLength : i + MAX_LENGTH;
                    const chunk = bytes.slice(i, end);
                    await characteristic.writeValueWithoutResponse(chunk);
                    await sleep(50);
                }


            }

        } catch (error) {
            errorToast(`${error}`)
        }
    }

    const renderReceiptData = async (sale: MerchantSale, merchant: Merchant, paperWidth: number): Promise<Uint8Array> => {
        const receipt = (
            <Printer type="epson" width={paperWidth} debug={true}>
                {/* {merchant?.logo && <Image src={merchant.logo} width={48} align="center" transforms={[transforms.floydSteinberg]}  />} */}
                <Text align="center" size={{ width: 2, height: 2 }}>{merchant.name}</Text>
                <Text align="center">{merchant.address}{merchant.phone && ` - ${merchant.phone}`}</Text>
                <Line />
                <Row left={<Text>No.</Text>} right={<Text>{sale.code}</Text>} />
                <Row left={<Text>Tgl</Text>} right={<Text>{moment(sale.date).format('DD MMM YYYY')}</Text>} />
                <Row left={<Text>Kons.</Text>} right={<Text>{sale.customer_name}</Text>} />
                <Line />
                <Row left={<Text bold >Item</Text>} right={<Text bold >Jumlah</Text>} />
                <Line />
                {(sale.items ?? []).filter(e => e.status != "Cancel").map((item) => {
                    let desc = `${item.qty} x ${item.description}`
                    return <Row gap={1} key={item.uuid} left={desc} right={money(item.amount)} />;
                })}
                <Line />
                <Row left={<Text bold>Total</Text>} right={<Text>{money(sale.grand_total)}</Text>} />
                {sale.discount_total > 0 && <Row left={<Text bold>Disk.</Text>} right={<Text>{money(sale.discount_total)}</Text>} />}
                {(sale.tax_amount > 0) && <Row left={<Text bold>Pajak.</Text>} right={<Text>{money(sale.tax_amount)}</Text>} />}
                <Br />
                <Text align="center">{merchant.message.replace(/(<([^>]+)>)/gi, "")}`</Text>
                {qrString && <Image src={qrString} width={48} align='center' />}
                <Br />
                <Br />
                <Br />
            </Printer>
        );

        return await render(receipt);
    }

    const styles = StyleSheet.create({
        defaultFont: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 6
        },
        header: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 8
        },
        cellStyle: {
            border: 'solid',
            borderWidth: 0.4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderColor: "black"
        }
    });

    const inlineData = (label: string, data: string) => (<div key={randomStr(3)} style={{ display: 'flex', flexDirection: "row", paddingBottom: 2, marginBottom: 1.5, justifyContent: 'space-between' }}>
        <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: "0%", marginRight: 5 }}>
            <p style={{ ...styles.defaultFont }}>{label}</p>
        </div>
        <div style={{ minWidth: 40, display: 'flex', alignItems: 'flex-end' }}>
            <p style={{ ...styles.defaultFont, textAlign: 'center' }}>{data}</p>
        </div>
    </div>)



    const getQRMessage = async (msg: string): Promise<string> => {
        return await new Promise((resolve, reject) => {
            QRCode.toDataURL(msg, (err, code) => {
                if (err) {
                    reject(reject);
                    return;
                }
                // console.log(code)
                resolve(code);
            });
        });
    }

    return (<ModalCustom title='Cetak Struk' isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => onClose}
        footer={
            <div className='flex flex-row justify-between'>
                <button className="bg-pink-600 text-white px-4 py-2 rounded-full hover:bg-pink-700 flex items-center text-sm" onClick={() => window.open(`/pos/${sale!.uuid}/receipt`)}>
                    <IoCloudDownloadOutline className="mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" />   Download
                </button>
                <button className="bg-orange-600 text-white px-4 py-2 rounded-full hover:bg-orange-700 flex items-center text-sm" onClick={() => printReceipt()}>
                    <IoPrintOutline className="mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" />   Print
                </button>
            </div>
        }
    >
        {merchant && sale &&

            <div className='flex justify-center items-center overflow-y-auto bg-gray-100 p-4'>
                <div className='bg-white p-4 rounded-md border' style={{ width: "58mm", minHeight: "100mm", border: 0, paddingLeft: "3mm", paddingRight: "3mm" }}>
                    
                    <p style={{ ...styles.header, textAlign: 'center' }}>{merchant!.name}</p>
                    <p style={{ ...styles.defaultFont, textAlign: 'center', marginBottom: 10 }}>{merchant!.address} {merchant!.phone}</p>
                    <div style={{ display: 'flex', flexDirection: "column" }} >
                        <p style={{ ...styles.defaultFont, textAlign: 'left' }}>Tgl: {moment(sale!.date).format("DD/MM/YYYY HH:mm")} </p>
                        <p style={{ ...styles.defaultFont, textAlign: 'left' }}>Kasir: {sale!.cashier_name} </p>
                        {sale!.customer_name && <p style={{ ...styles.defaultFont, textAlign: 'left' }}>Cust: {sale!.customer_name} </p>}
                        <div style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, borderBottom: 0.2, marginBottom: 5 }}></div>
                        {sale!.items.filter(e => e.status != "Cancel").map(e => inlineData(`${e.description} x${money(e.qty)}`, `${money(e.amount)}`))}
                        <div style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, borderBottom: 0.2, marginBottom: 5 }}></div>
                        {inlineData(`Subtotal`, `${money(sale!.subtotal)}`)}
                        {sale!.discount_total > 0 && inlineData(`Disk.`, `${money(sale!.discount_total)}`)}
                        {sale!.tax_amount > 0 && inlineData(`${sale!.tax_name}`, `${money(sale!.tax_amount)}`)}
                        {inlineData(`TOTAL`, `${money(sale!.grand_total)}`)}
                        <div style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, marginBottom: 5 }}></div>

                        <p style={{ ...styles.defaultFont, textAlign: 'center' }}>{merchant!.message}</p>
                        {qrString != "" &&
                            <div style={{ display: 'flex', flexDirection: "column", paddingBottom: 5, marginBottom: 5, justifyContent: 'center', alignItems: 'center' }}>
                                <Image src={qrString} width={48} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        }
    </ModalCustom>);
}
export default ModalReceipt;