import { MerchantSale, merchantSaleToReq } from "../../model/pos";
import { getSales, sendPosReport } from "../../repositories/pos";
import moment from "moment";
import "moment/locale/id";
import { Fragment, useContext, useEffect, useState, type FC } from "react";
import {
  Button,
  Checkbox,
  DateRangePicker,
  Divider,
  Modal,
  SelectPicker,
} from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import CustomTable from "../custom_table";
import Moment from "react-moment";
import { money } from "../../utils/number";
import Badge from "../badge";
import { Pagination } from "../../model/pagination";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FolderIcon } from "@heroicons/react/24/outline";

import ModalCustom from "../modals/modal_custom";
import {
  getSelectedMerchant,
  getStorageDateRange,
  setNullString,
} from "../../utils/helper";
import { Merchant } from "../../model/merchant";
import InlineForm from "../inline_form";
import {
  getMerchantAccounts,
  getMerchantStores,
} from "../../repositories/merchant";
import { Store } from "../../model/store";
import { Account } from "../../model/account";
import Swal from "sweetalert2";
import { LoadingContext } from "../../objects/loading_context";
import { useNavigate } from "react-router-dom";
import { SelectOption, colourStyles } from "../../utils/style";
import Select, { SingleValue } from "react-select";
import ModalReceipt from "../modals/modal_receipt";

interface PosSalesProps {}

const PosSales: FC<PosSalesProps> = ({}) => {
  const [dateRange, setDateRange] = useState<DateRange>([
    moment().subtract("1", "days").toDate(),
    moment().toDate(),
  ]);
  const [mounted, setMounted] = useState(false);
  const [sales, setSales] = useState<MerchantSale[]>([]);
  const [selectedSales, setSelectedSales] = useState<MerchantSale[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [mode, setMode] = useState("all");
  const nav = useNavigate();
  const [modalReportOpen, setModalReportOpen] = useState(false);
  const [inputTitle, setInputTitle] = useState("");
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  const [stores, setStores] = useState<Store[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [storeId, setStoreId] = useState("");
  const [accountSaleId, setAccountSaleId] = useState("");
  const [inputDesc, setInputDesc] = useState("");
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [selectedStore, setSelectedStore] =
    useState<SingleValue<SelectOption>>(null);
  const [selectedAccountSale, setSelectedAccountSale] =
    useState<SingleValue<SelectOption>>(null);
  const [modalReceiptOpen, setModalReceiptOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState<MerchantSale | null>(
    null
  );
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedAccountSaleId, setSelectedAccountSaleId] = useState("");
  const menuData: { name: string; key: string }[] = [
    { name: "Semua Data", key: "all" },
    { name: "Sudah direport", key: "reported" },
    { name: "Belum direport", key: "not-reported" },
  ];
  useEffect(() => {
    getStorageDateRange().then((v) => {
      if (!mounted && v) {
        setDateRange([moment(v[0]).toDate(), moment(v[1]).toDate()]);
      }
      setMounted(true);
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllSales();
    getData();
    getMerchantStores()
      .then((v) => v.json())
      .then((v) => setStores(v.data));
    getMerchantAccounts()
      .then((v) => v.json())
      .then((v) => setAccounts(v.data));
  }, [mounted]);

  const getData = async () => {
    setMerchant(await getSelectedMerchant());
  };

  useEffect(() => {
    if (merchant) {
        setSelectedStoreId(merchant!.default_store_id)
        setSelectedAccountSaleId(merchant!.default_sale_account_id)
    }
  }, [merchant]);
  useEffect(() => {
    getAllSales();
  }, [dateRange, page, limit, search, mode]);

  const getAllSales = () => {
    let isReported: boolean | null = null;
    switch (mode) {
      case "reported":
        isReported = true;
        break;
      case "not-reported":
        isReported = false;
        break;

      default:
        isReported = null;
        break;
    }
    // console.log("isReported", isReported)
    getSales(dateRange, { page, limit, search }, isReported)
      .then((v) => v.json())
      .then((v) => {
        setSales(v.data);
        setPagination(v.meta);
      });
  };

  useEffect(() => {
    if (merchant) {
      console.log(merchant);
    }
  }, [merchant]);
  const sendReport = async () => {
    try {
      if (!inputTitle) {
        throw new Error("Judul harus di isi");
      }
      if (!selectedAccountSaleId) {
        throw new Error("Akun Penjualan harus di isi");
      }
      if (!selectedStoreId) {
        throw new Error("Gudang harus di isi");
      }
      setIsLoading(true);
      await sendPosReport({
        account_source_id: setNullString(selectedAccountSaleId),
        store_id: setNullString(selectedStoreId),
        title: inputTitle,
        notes: inputDesc,
        date: moment().toISOString(),
        amount_cash: selectedSales
          .filter((e) => e.payment_method == "CASH")
          .map((e) => e.grand_total)
          .reduce((a, b) => a + b, 0),
        amount_card: selectedSales
          .filter((e) => e.payment_method != "CASH")
          .map((e) => e.grand_total)
          .reduce((a, b) => a + b, 0),
        grand_total:
          selectedSales.map((e) => e.grand_total).reduce((a, b) => a + b, 0) ??
          0,
        sales: selectedSales.map((e) => {
          return {
            uuid: setNullString(e.uuid),
            payment_method: e.payment_method,
            grand_total: e.grand_total,
            account_destination_id: setNullString(e.account_destination_id),
            machine_id: setNullString(e.machine_id),
          };
        }),
      });
      setModalReportOpen(false);
      setSelectedSales([]);
      getAllSales();
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const renderMenu = () => (
    <Menu as="div" className="relative inline-block text-left ml-4">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md bg-gray-100  px-4 py-2 text-sm font-medium  hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          {menuData.find((e) => e.key == mode)?.name ?? ""}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            {menuData.map((e) => (
              <Menu.Item key={e.key}>
                {({ active }) => (
                  <button
                    onClick={() => setMode(e.key)}
                    className={`${
                      e.key == mode ? "bg-pink-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {e.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
  return (
    <div className="flex-1 mb-5col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
      <div className="flex justify-end mb-4  mt-5 ">
        <DateRangePicker
          placement="bottomEnd"
          value={dateRange}
          block
          format="dd/MM/yyyy"
          onOk={(val: DateRange) => {
            setDateRange(val);
          }}
        />

        {renderMenu()}
        {selectedSales.length > 0 && (
          <Button
            color="green"
            className="ml-4"
            appearance="primary"
            onClick={() => {
              setModalReportOpen(true);
              setInputTitle(
                `Laporan ${merchant?.name} - ${moment()
                  .locale("id")
                  .format("DD MMMM YYYY HH:mm")}`
              );
              setStoreId(merchant?.default_store_id ?? "");
            }}
          >
            Buat Laporan
          </Button>
        )}
      </div>
      <CustomTable
        pagination
        total={pagination?.total_records}
        limit={limit}
        activePage={page}
        setActivePage={(val) => {
          setPage(val);
        }}
        changeLimit={(val) => setLimit(val)}
        limitPaginations={[10, 20, 50, 100, 1000]}
        headers={[
          <div>
            <Checkbox
              onChange={(value, checked) => {
                if (checked) {
                  setSelectedSales(sales.filter((e) => !e.reported));
                } else {
                  setSelectedSales([]);
                }
              }}
            />
          </div>,
          "Tgl",
          "Kode",
          "Pelanggan",
          "Total",
          "Status",
        ]}
        headerClasses={[
          "w-10",
          "w-36",
          "w-10",
          "",
          "text-right",
          "w-48 text-center",
        ]}
        datasets={sales.map((e) => {
          return {
            cells: [
              {
                data: (
                  <div className="flex items-center">
                    <Checkbox
                      disabled={e.reported}
                      checked={selectedSales.includes(e)}
                      defaultChecked={e.reported}
                      onChange={(value, checked) => {
                        setSelectedSales(
                          selectedSales.includes(e)
                            ? selectedSales.filter((s) => s.code != e.code)
                            : [...selectedSales, e]
                        );
                      }}
                    />
                    {(page - 1) * limit + (sales.indexOf(e) + 1)}
                  </div>
                ),
              },
              {
                data: (
                  <div className="flex flex-col">
                    <Moment format="DD MMM YYYY">{e.date}</Moment>
                    <Moment className="text-xs" format="HH:mm">
                      {e.date}
                    </Moment>
                  </div>
                ),
              },
              {
                data: (
                  <div
                    className=" hover:font-semibold"
                    onClick={() => {
                      setSelectedReceipt(e);
                      setModalReceiptOpen(true);
                      // window.open(`/pos/${e.uuid}/receipt`)
                    }}
                  >
                    {e.code}
                  </div>
                ),
              },
              { data: e.customer_name },
              {
                data: <div>{money(e.grand_total)}</div>,
                className: "text-right",
              },
              {
                data: (
                  <div className="flex justify-center">
                    {e.reported ? (
                      <Badge color="green" label="Sudah Direport" />
                    ) : (
                      <Badge color="red" label="Belum Direport" />
                    )}
                  </div>
                ),
              },
            ],
            className: "last:border-0 hover:bg-gray-50",
          };
        })}
      />
      <Modal
        size={"lg"}
        open={modalReportOpen}
        onClose={() => setModalReportOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Buat Laporan</Modal.Title>
        </Modal.Header>
        <InlineForm title={"Judul Laporan"} className="mt-4">
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder="Judul"
            value={inputTitle}
            onChange={(e) => setInputTitle(e.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Gudang"} className="mt-4">
          <SelectPicker
            block
            data={stores}
            valueKey="uuid"
            labelKey="name"
            value={selectedStoreId}
            onChange={(val) => {
              setSelectedStoreId(val!);
            }}
          />
        </InlineForm>
        <InlineForm title={"Akun Penjualan"} className="mt-4">
          <SelectPicker
            block
            valueKey="uuid"
            labelKey="name"
            data={accounts.filter((e) => e.type == "Income")}
            value={selectedAccountSaleId}
            onChange={(val) => {
              setSelectedAccountSaleId(val!);
            }}
          />
        </InlineForm>
        <InlineForm
          title={"Keterangan"}
          className="mt-4"
          style={{ alignItems: "start" }}
        >
          <textarea
            rows={5}
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder="Keterangan"
            value={inputDesc}
            onChange={(e) => setInputDesc(e.target.value)}
          />
        </InlineForm>
        <CustomTable
          className="mb-4"
          headers={["No", "Kode", "Total", "Pembayaran"]}
          headerClasses={["", "", "text-right"]}
          footer={[
            {
              cells: [
                { data: "" },
                {
                  data: (
                    <div className=" font-semibold">Total Penjualan CASH</div>
                  ),
                },
                {
                  data: money(
                    selectedSales
                      .filter((e) => e.payment_method == "CASH")
                      .map((e) => e.grand_total)
                      .reduce((a, b) => a + b, 0)
                  ),
                  className: "text-right",
                },
                { data: "" },
              ],
            },
            {
              cells: [
                { data: "" },
                {
                  data: (
                    <div className=" font-semibold">
                      Total Penjualan NON CASH
                    </div>
                  ),
                },
                {
                  data: money(
                    selectedSales
                      .filter((e) => e.payment_method != "CASH")
                      .map((e) => e.grand_total)
                      .reduce((a, b) => a + b, 0)
                  ),
                  className: "text-right",
                },
                { data: "" },
              ],
            },
            {
              cells: [
                { data: "" },
                { data: <div className=" font-semibold">Total Penjualan</div> },
                {
                  data: money(
                    selectedSales
                      .map((e) => e.grand_total)
                      .reduce((a, b) => a + b, 0)
                  ),
                  className: "text-right",
                },
                { data: "" },
              ],
            },
          ]}
          datasets={selectedSales.map((e) => {
            return {
              cells: [
                { data: selectedSales.indexOf(e) + 1 },
                { data: e.code },
                { data: money(e.grand_total), className: "text-right" },
                { data: e.payment_method },
              ],
              className: "last:border-0 hover:bg-gray-50",
            };
          })}
        />
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={sendReport}
            >
              <FolderIcon className=" h-5 w-5 mr-2" aria-hidden="true" /> Simpan
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalReceipt
        isOpen={modalReceiptOpen}
        setIsOpen={setModalReceiptOpen}
        onClose={() => setModalReceiptOpen(false)}
        sale={selectedReceipt}
        merchant={merchant!}
      />
    </div>
  );
};
export default PosSales;
