import { useContext, useEffect, useState, type FC } from "react";
import { MerchantOrder, MerchantSale, merchantSaleToReq } from "../model/pos";
import { Machine, Merchant } from "../model/merchant";
import Avatar from "./avatar";
import { money } from "../utils/number";
import {
  countDiscount,
  countGrandTotal,
  countSubTotal,
  countTax,
} from "../utils/pos_helper";
import { getTaxDetail } from "../repositories/tax";
import { Tax } from "../model/tax";
import { Profile } from "../model/profile";
import {
  getProfileStorage,
  getStorageMachine,
  randomStr,
  setStorageMachine,
} from "../utils/helper";
import moment from "moment";
import { DINE_IN } from "../objects/constants";
import { getMerchantMachines } from "../repositories/merchant";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { paymentMethodsData, paymentProvidersData } from "../objects/pos";
import CurrencyInput from "react-currency-input-field";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { LoadingContext } from "../objects/loading_context";
import { getPosSaleDetail, sendPosSale } from "../repositories/pos";
import ModalReceipt from "./modals/modal_receipt";

interface RestoPaymentProps {
  order: MerchantOrder;
  merchant: Merchant;
}

const RestoPayment: FC<RestoPaymentProps> = ({ order, merchant }) => {
    const [mounted, setMounted] = useState(false);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [tax, setTax] = useState<Tax>();
  const [sale, setSale] = useState<MerchantSale>();
  const [profile, setProfile] = useState<Profile>();
  const [notes, setNotes] = useState("");
  const [amountPayment, setAmountPayment] = useState(0);
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [paymentProviderGroup, setPaymentProviderGroup] = useState("");
  const [paymentProvider, setPaymentProvider] = useState("CASH");
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [inputOrderName, setInputOrderName] = useState("");
  const [orderType, setOrderType] = useState<string>(DINE_IN);
  const [inputCustomerName, setInputCustomerName] = useState("");
  const [machines, setMachines] = useState<Machine[]>([]);
  const [mountPayment, setMountPayment] = useState(false);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [listAmountPayment, setListAmountPayment] = useState<number[]>([]);
  const [modalReceiptOpen, setModalReceiptOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState<MerchantSale | null>(
    null
  );
  useEffect(() => {
    setMounted(true)
  
  }, []);
  useEffect(() => {
    getProfileStorage().then((v) => setProfile(v));
    getStorageMachine().then((v) => setSelectedMachine(v));
    getMerchantMachines()
      .then((v) => v.json())
      .then((v) => setMachines(v.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (merchant && merchant.tax_id) {
      //   console.log(merchant);
      getTaxDetail(merchant.tax_id)
        .then((v) => v.json())
        .then((v) => {
          setTax(v.data);
        });
    }

    if (merchant && profile) {
      let selProvider = merchant!.payment_providers.find(
        (e) => e.provider == paymentProvider
      );
      setInputCustomerName(order?.customer_name);
      setAmountPayment((val) => {
        setMountPayment(false)
        setTimeout(() => {
            setMountPayment(true)
        }, 50);
        return countGrandTotal(order.data, merchant, tax)
      });
      setTotalOrderAmount(countGrandTotal(order.data, merchant, tax));
      

      let req: MerchantSale = {
        date: moment().toISOString(),
        cashier_id: profile!.uuid,
        cashier_name: profile!.full_name,
        merchant_id: merchant.uuid,
        customer_id: order?.customer_id,
        customer_name: inputCustomerName,
        notes: notes,
        subtotal: countSubTotal(order.data),
        discount_total: countDiscount(order.data),
        total: countSubTotal(order.data) - countDiscount(order.data),
        tax_amount: countTax(order.data, merchant, tax),
        total_tax: countTax(order.data, merchant, tax),
        tax_name: tax?.name ?? "",
        count_item: order.data.length,
        grand_total: countGrandTotal(order.data, merchant, tax),
        change: amountPayment - countGrandTotal(order.data, merchant, tax),
        payment_amount: amountPayment,
        payment_method: paymentMethod,
        payment_provider: paymentProvider,
        account_destination_id: selProvider!.account_destination_id!,
        order_name: inputOrderName,
        order_type_provider: selectedProvider,
        order_type: orderType,
        machine_id: selectedMachine?.uuid ?? "",
        code: randomStr(5).toUpperCase(),
        items: order.data,
        merchant_order_id: order?.uuid,
      };
      setSale(req);
    }
  }, [merchant, profile, mounted]);
  const clearForm = () => {
    setPaymentMethod("CASH");
    setPaymentProviderGroup("");
    setPaymentProvider("CASH");
    setAmountPayment(0);
    setInputCustomerName("");
    setAmountPayment(0);
  };
  useEffect(() => {
    if (totalOrderAmount > 0) {
      let listAmount = Array.from(
        new Set([
          totalOrderAmount,
          ...[50000, 100000, 200000, 500000, 600000, 750000, 1000000].map(
            (roundValue) =>
              Math.ceil(totalOrderAmount / roundValue) * roundValue
          ),
        ])
      );
      setListAmountPayment(listAmount.sort((a, b) => a - b));
    }
  }, [totalOrderAmount]);
  const sendSale = async () => {
    try {
      setIsLoading(true);
      let selProvider = merchant!.payment_providers.find(
        (e) => e.provider == paymentProvider
      );

      var resp = await sendPosSale(merchantSaleToReq(sale!));
      var respJson = await resp.json();
      getPosSaleDetail(respJson.data.last_id)
        .then((v) => v.json())
        .then((v) => {
          setSelectedReceipt(v.data);
          setModalReceiptOpen(true);
        });
      // window.open(`/pos/${respJson.data.last_id}/receipt`)
      clearForm();
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="w-full h-full flex flex-row">
      <div className="w-1/4 p-4">
        <h3 className=" text-xl mb-4 font-semibold">Item Pesanan</h3>
        {order.data.filter(e => e.status != "Cancel").map((e, i) => (
          <div key={i} className="mb-4">
            <div className="flex items-center justify-between">
              <div className="flex flex-row">
                <Avatar
                  name={e.product?.name}
                  url={e.product?.default_picture}
                />
                <div>
                  <p className="font-semibold">{e.product?.name}</p>
                  <small>
                    {money(e.qty)} {e.unit_name} x {money(e.price)}
                  </small>
                  {e.status == "Done" && (
                    <div className=" w-24 bg-green-600 hover:bg-green-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg">
                      Selesai
                    </div>
                  )}
                  <p className="text-sm">{e.notes}</p>
                </div>
              </div>
              <p className="font-semibold text-orange-600">
                {money(e.qty * e.price)}
              </p>
            </div>
          </div>
        ))}
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <p className=" font-semibold text-sm">Subtotal</p>
            <h3 className="font-[500] text-lg">
              {money(countSubTotal(order.data ?? []))}
            </h3>
          </div>
          {countDiscount(order.data ?? []) > 0 && (
            <div className="flex justify-between items-center">
              <p className=" font-semibold text-sm">Total Diskon</p>
              <h3 className="font-[500] text-lg">
                {money(countDiscount(order.data ?? []))}
              </h3>
            </div>
          )}
          {merchant != null && tax && (
            <div className="flex justify-between items-center">
              <p className=" font-semibold text-sm">
                {tax.name} ({tax.amount}%)
              </p>
              <h3 className="font-[500] text-normal">
                {money(countTax(order.data ?? [], merchant, tax))}
              </h3>
            </div>
          )}
          <div className="flex justify-between items-center">
            <p className=" font-semibold text-sm">Total</p>
            <h3 className="font-[500] text-lg">
              {money(countGrandTotal(order.data ?? [], merchant, tax))}
            </h3>
          </div>
        </div>
      </div>
      <div className="w-2/4 border-l border-r h-full p-4 flex flex-col space-y-2">
        <div className="flex-1">
          <div>
            <div className="grid gap-x-4 gap-y-4 grid-cols-2">
              <div>
                <h3 className="text-lg mb-2">Konsumen</h3>
                <div className="relative">
                  <input
                    placeholder="Nama Konsumen"
                    value={inputCustomerName}
                    onChange={(val) => {
                      setInputCustomerName(val.target.value);
                      if (val.target.value == "") {
                      }
                    }}
                    className="bg-white mb-4  text-lg w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  />
                  <div className="absolute right-2 top-2.5 cursor-pointer">
                    <MagnifyingGlassIcon
                      className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-lg mb-2">Nama Order</h3>
                <div className="relative">
                  <input
                    placeholder="Nama Order"
                    value={inputOrderName}
                    onChange={(val) => {
                      setInputOrderName(val.target.value);
                    }}
                    className="bg-white mb-4  text-lg w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  />
                  <div className="absolute right-2 top-2.5 cursor-pointer"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-lg m-0">Pilih Cash Register</h3>
            <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
              {machines.map((e) => (
                <div
                  onClick={() => {
                    setSelectedMachine(e);
                    setStorageMachine(e);
                  }}
                  key={e.uuid}
                  className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                    e.uuid == selectedMachine?.uuid
                      ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                      : "bg-gray-50"
                  }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                >
                  {e.name}
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-lg m-0">Pilih Pembayaran</h3>
            <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
              {paymentMethodsData.map((e) => (
                <div
                  onClick={() => {
                    setPaymentMethod(e.value);
                    if (e.value == "CASH") {
                      setPaymentProviderGroup("");
                      setPaymentProvider("CASH");
                    }
                  }}
                  key={e.value}
                  className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                    e.value == paymentMethod
                      ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                      : "bg-gray-50"
                  }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                >
                  {e.name}
                </div>
              ))}
            </div>
          </div>
          <div>
            {paymentMethod != "CASH" && (
              <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
                {paymentProvidersData.map((e) => (
                  <div
                    onClick={() => {
                      setPaymentProviderGroup(e.name);
                      setPaymentProvider("");
                    }}
                    key={e.name}
                    className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                      e.name == paymentProviderGroup
                        ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                        : "bg-gray-50"
                    }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            )}
            {paymentMethod != "CASH" && paymentProviderGroup != "" && (
              <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
                {paymentProvidersData
                  .find((e) => e.name == paymentProviderGroup)!
                  .children.map((e) => (
                    <div
                      onClick={() => {
                        setPaymentProvider(e.value);
                      }}
                      key={e.name}
                      className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                        e.value == paymentProvider
                          ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                          : "bg-gray-50"
                      }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                    >
                      {e.name}
                    </div>
                  ))}
              </div>
            )}
            {paymentMethod == "CASH" && (
              <h3 className="text-lg m-0 text-right">Jumlah</h3>
            )}
            {paymentMethod == "CASH" &&
              (mountPayment ? (
                <CurrencyInput
                  className="bg-white mb-4  text-4xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  groupSeparator="."
                  decimalSeparator=","
                  placeholder="Jumlah"
                  defaultValue={amountPayment}
                  decimalsLimit={0}
                  onValueChange={(value, _, values) => {
                    setAmountPayment(values!.float!);
                  }}
                />
              ) : (
                <div className="bg-white mb-4  text-4xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                  {money(amountPayment)}
                </div>
              ))}
            {paymentMethod == "CASH" && (
              <div className="grid gap-x-4 gap-y-4 grid-cols-6 mb-4">
                {listAmountPayment.map((roundValue, i) => {
                  return (
                    <button
                      key={roundValue}
                      onClick={() => {
                        setAmountPayment(roundValue);
                        setMountPayment(false);
                        setTimeout(() => {
                          setMountPayment(true);
                        }, 50);
                      }}
                      className={`rounded-md py-3 px-7 justify-center items-center flex text-sm text-center ${
                        amountPayment == roundValue
                          ? "bg-orange-400 hover:bg-orange-600 font-semibold text-white"
                          : "bg-gray-50"
                      }  hover:bg-gray-200 cursor-pointer hover:shadow-xl`}
                    >
                      {totalOrderAmount == roundValue
                        ? "UANG PAS"
                        : money(roundValue)}
                    </button>
                  );
                })}
              </div>
            )}
            {paymentMethod == "CASH" &&
              amountPayment - totalOrderAmount != 0 && (
                <h3 className="text-lg m-0  text-right">Kembalian</h3>
              )}
            {paymentMethod == "CASH" &&
              amountPayment - totalOrderAmount != 0 && (
                <h3 className="text-2xl m-0 text-orange-600 text-right">
                  {money(amountPayment - totalOrderAmount)}
                </h3>
              )}
          </div>
          <div>
            <h3 className="text-lg m-0 text-right">Catatan</h3>
            <textarea
              placeholder="Catatan"
              value={notes}
              rows={3}
              onChange={(val) => setNotes(val.target.value)}
              className="bg-white mb-4  text-xl text-right w-full appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={sendSale}
            className=" flex-1 text-white flex justify-center items-center bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-base  px-5 py-2.5 text-center "
          >
            <FaRegMoneyBillAlt className="w-8 mr-2 " /> Proses Pembayaran
          </button>
        </div>
      </div>
      <div className="w-1/4 h-full p-4 flex flex-col space-y-2">
      {paymentProvider == "QRIS" && <img src={merchant?.qris_static_picture} />}
      </div>
      <ModalReceipt
        isOpen={modalReceiptOpen}
        setIsOpen={setModalReceiptOpen}
        onClose={() => setModalReceiptOpen(false)}
        sale={selectedReceipt}
        merchant={merchant!}
      />
    </div>
  );
};
export default RestoPayment;
