//

import { TableOccupyReq } from "../model/merchant";
import { Pagination, PaginationReq } from "../model/pagination";
import {
  MerchantOrderReq,
  MerchantSaleItem,
  MerchantSaleItemReq,
  MerchantSaleReportReq,
  MerchantSaleReq,
} from "../model/pos";
import { SELECTED_MERCHANT_ID } from "../objects/constants";
import { PosReq } from "../objects/pos";
import { asyncLocalStorage, customFetch } from "../utils/helper";
import moment from "moment";
import "moment/locale/id";
import { DateRange } from "rsuite/esm/DateRangePicker";

export let getPosProducts = async (req: PosReq) => {
  var params: Record<string, string> = {};
  if (req.is_pinned) {
    params["is_pinned"] = req.is_pinned ? "1" : "0";
  }
  if (req.product_category_id) {
    params["product_category_id"] = req.product_category_id;
  }
  if (req.search) {
    params["search"] = req.search;
  }
  if (req.barcode) {
    params["barcode"] = req.barcode;
  }
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/Product?${new URLSearchParams(params)}`
  );
};
export let getPosProductCategories = async () => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/ProductCategory`);
};
export let getPosProductByBarcode = async (barcode: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Merchant/${merchantId}/Product?barcode=${barcode}`);
};
export let getPosSaleDetail = async (saleId: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Sale/${saleId}`);
};
export let getPosDiscounts = async () => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Discount`);
};
export let getSales = async (
  range: DateRange,
  req: PaginationReq,
  isReported?: boolean | null
) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/Sale?${
      isReported != null ? `is_reported=${isReported ? "1" : "0"}` : ""
    }&page=${req.page}&limit=${req.limit}&search=${
      req.search
    }&start_date=${moment(range[0]).format("YYYY-MM-DD")}&end_date=${moment(
      range[1]
    ).format("YYYY-MM-DD")}`
  );
};
export let getReports = async (range: DateRange, req: PaginationReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/Report?page=${req.page}&limit=${req.limit}&search=${
      req.search
    }&start_date=${moment(range[0]).format("YYYY-MM-DD")}&end_date=${moment(
      range[1]
    ).format("YYYY-MM-DD")}`
  );
};
export let getReportDownload = async (reportId: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Report/${reportId}/Download`);
};
export let getReportDetail = async (
  reportId: string,
  selectedMerchantId?: string | null
) => {
  const merchantId =
    selectedMerchantId ??
    (await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID));
  return await customFetch(`Pos/${merchantId}/Report/${reportId}`);
};

export let addPosStation = async (req: any) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Station`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export let addPosRoom = async (req: any) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export let getPosRoom = async () => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room`, {});
};
export let getPosRoomDetail = async (id: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room/${id}`, {});
};
export let roomTableOccupy = async (id: string, req: TableOccupyReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room/${id}/Occupy`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let roomTableOccupyDetail = async (id: string, occupyId: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/Room/${id}/Occupy/${occupyId}`,
    {}
  );
};
export let updateRoomTableOccupy = async (
  id: string,
  occupyId: string,
  req: any
) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room/${id}/Occupy/${occupyId}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let updatePosRoomDetail = async (id: string, req: any) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room/${id}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let deleteRoom = async (id: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Room/${id}`, {
    method: "DELETE",
  });
};

export let getPosStation = async () => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Station`, {});
};
export let getPosStationOrder = async (stationId?: string | null) => {
  var params: Record<string, string> = {};
  if (stationId) {
    params["station_id"] = stationId;
  }
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/StationOrder?${new URLSearchParams(params)}`,
    {}
  );
};
export let updateStatusPosStationOrder = async (
  orderId: string,
  status: string,
  remarks: string
) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/StationOrder/${orderId}/Status`, {
    method: "PUT",
    body: JSON.stringify({ status, remarks }),
  });
};
export let updateItemPosStationOrder = async (
  orderId: string,
  uniqueId: string
) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/StationOrder/${orderId}/Item/${uniqueId}/Done`,
    {
      method: "PUT",
    }
  );
};

export let deleteStation = async (id: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Station/${id}`, {
    method: "DELETE",
  });
};

export let sendPosSale = async (req: MerchantSaleReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Sale`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export let sendPosReport = async (req: MerchantSaleReportReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Report`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export let getPosOrders = async (pagination: PaginationReq, dateRange?: DateRange | null) => {
  var params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };

  if (pagination.status) {
    params["status"] = pagination.status;
  }
  if (pagination.search) {
    params["search"] = pagination.search;
  }

  if (dateRange) {
    params["start_date"] = dateRange[0].toISOString();
    params["end_date"] = dateRange[1].toISOString();
  }
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(
    `Pos/${merchantId}/Order?${new URLSearchParams(params)}`,
    {}
  );
};
export let addPosOrder = async (req: MerchantOrderReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export let getPosOrderDetail = async (orderId: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order/${orderId}`, {
  });
};
export let addItemPosOrder = async (orderId: string, req: MerchantOrderReq) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order/${orderId}/Item`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let cancelPosOrderItem = async (orderId: string, uniqueId: string, remarks: string) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order/${orderId}/Item/${uniqueId}/Cancel`, {
    method: "PUT",
    body: JSON.stringify({remarks}),
  });
};
export let editPosOrderItem = async (orderId: string, uniqueId: string, req: any) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order/${orderId}/Item/${uniqueId}/Edit`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export let splitPosOrder = async (
  orderId: string,
  req: { customer_name: string; original: any; new: any }
) => {
  const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  return await customFetch(`Pos/${merchantId}/Order/${orderId}/SplitBill`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
