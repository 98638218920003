import React, { ReactNode, useEffect } from "react";
import { Shape } from "../model/shape";
import Konva from "konva";
import {
    Circle,
    Ellipse,
    Group,
    Layer,
    Line,
    Rect,
    RegularPolygon,
    Stage,
    Star,
    Text,
    Transformer,
  } from "react-konva";

export const RenderShape: React.FC<{
    isEditable: boolean;
  width: number;
  height: number;
  shape: Shape;
  onTap: (shape: Shape) => void;
  onChange: (shape: Shape) => void;
  isSelected: boolean;
}> = ({ shape, onTap, onChange, isSelected, width, height, isEditable }) => {
  const shapeRef = React.useRef(null);
  const trRef = React.useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (isSelected) {
      if (trRef.current && shapeRef.current) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
      // we need to attach transformer manually
    }
  }, [isSelected]);

  const commonProps = {
    draggable: true,
    onClick: (e: any) => {
      onTap(shape);
    },
    onDragEnd: (e: any) => {
      onChange({
        ...shape,
        x: e.target.x(),
        y: e.target.y(),
      });
    },
    onTransformEnd: (e: any) => {
      const scaleX = e.target.scaleX();
      const scaleY = e.target.scaleY();

      // we will reset it back
      e.target.scaleX(1);
      e.target.scaleY(1);
      onChange({
        ...shape,
        x: e.target.x(),
        y: e.target.y(),
        radiusX: e.target.radiusX
          ? Math.max(5, e.target.radiusX() * scaleX)
          : 0,
        radiusY: e.target.radiusY
          ? Math.max(5, e.target.radiusY() * scaleY)
          : 0,
        rotation: e.target.rotation(),

        // set minimal value
        width: Math.max(5, e.target.width() * scaleX),
        height: Math.max(e.target.height() * scaleY),
      });
    },
  };

  let newShape: ReactNode;

  switch (shape.type) {
    case "rect":
      newShape = <Rect ref={shapeRef} {...shape} {...commonProps} />;
      break;
    case "circle":
      newShape = <Circle ref={shapeRef} {...shape} {...commonProps} />;
      break;
    case "ellipse":
      newShape = (
        <Ellipse
          ref={shapeRef}
          {...shape}
          {...commonProps}
          radiusX={shape.radiusX ?? 50}
          radiusY={shape?.radiusY ?? 50}
          width={shape?.width ?? 50}
          height={shape?.height ?? 50}
        />
      );
      break;
    case "line":
      newShape = (
        <Line
          ref={shapeRef}
          {...commonProps}
          points={shape.points}
          stroke={shape.fill}
          strokeWidth={2}
        />
      );
      break;
    case "text":
      newShape = <Text ref={shapeRef} {...shape} {...commonProps} />;
      break;
    case "polygon":
      newShape = (
        <RegularPolygon
          ref={shapeRef}
          {...shape}
          {...commonProps}
          sides={shape?.sides ?? 3}
          radius={shape.radius ?? 0}
        />
      );
      break;
    case "star":
      newShape = (
        <Star
          ref={shapeRef}
          {...shape}
          {...commonProps}
          numPoints={shape.numPoints ?? 5}
          innerRadius={shape.innerRadius ?? 10}
          outerRadius={shape.outerRadius ?? 10}
        />
      );
      break;
    default:
      newShape = null;
  }

  let textX = shape.x - ((shape.text ?? shape.type).length * 4) / 2;
  let textY = shape.y - 6;
  if (shape.type == "rect") {
    textX =
      shape.x +
      (shape.width ?? 0) / 2 -
      ((shape.text ?? shape.type).length * 6) / 2;
    textY = shape.y + (shape.height ?? 0) / 2 - 6;
  }
  if (shape.type == "polygon") {
    textX = shape.x - ((shape.text ?? shape.type).length * 6) / 2;
    textY = shape.y - 8;
  }
  if (shape.rotation) {
    const radian = (Math.PI * shape.rotation) / 180;
    const x = textX - shape.x;
    const y = textY - shape.y;
    const newX = x * Math.cos(radian) - y * Math.sin(radian);
    const newY = x * Math.sin(radian) + y * Math.cos(radian);
    textX = shape.x + newX;
    textY = shape.y + newY;
  }

  return (
    <React.Fragment>
      <Group draggable>
        {newShape}
        <Text
          text={shape?.text ?? shape.type}
          x={textX}
          y={textY}
          fill={shape.textColor ?? "black"}
          fontSize={12}
          rotation={shape.rotation}
          align={"center"}
          verticalAlign={"middle"}
        />
      </Group>

      {isSelected && isEditable && (
        <Transformer
          ref={trRef}
          centeredScaling={true}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
         
        />
      )}
    </React.Fragment>
  );
};

