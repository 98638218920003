import { Customer } from "../model/customer";
import { Discount } from "../model/discount";
import { Merchant } from "../model/merchant";
import { MerchantSale, MerchantSaleItem } from "../model/pos";
import { Tax } from "../model/tax";
import { SAVED_SALES, SELECTED_MERCHANT_ID } from "../objects/constants";
import { asyncLocalStorage } from "./helper";

export const saveSale = async (sale: MerchantSale): Promise<MerchantSale[]> => {
  let merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  sale.merchant_id = merchantID;
  let saved = await getSales();
  saved.push(sale);
  asyncLocalStorage.setItem(SAVED_SALES, JSON.stringify(saved));
  return saved;
};

export const getSales = async (): Promise<MerchantSale[]> => {
  let merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID);
  let saved = await asyncLocalStorage.getItem(SAVED_SALES);
  if (saved) {
    let parsed = JSON.parse(saved) as MerchantSale[];
    return parsed.filter((e) => e.merchant_id == merchantID);
  }
  return [];
};
export const delSale = async (sale: MerchantSale): Promise<MerchantSale[]> => {
  let saved = await asyncLocalStorage.getItem(SAVED_SALES);
  if (saved) {
    let parsed = JSON.parse(saved) as MerchantSale[];
    let deleted = parsed.filter((e) => e.code != sale.code);
    asyncLocalStorage.setItem(SAVED_SALES, JSON.stringify(deleted));
    return deleted;
  }
  return [];
};

export const countItem = (
  item: MerchantSaleItem,
  discount?: Discount | null,
  merchant?: Merchant | null,
  tax?: Tax | null
): MerchantSaleItem => {
  let discountAmount = 0;
  let discountPercent = 0;
  if (discount != null) {
    if (discount.type == 1) {
      discountAmount = (item.price * discount.percent) / 100;
      discountPercent = discount.percent;
    }
    if (discount.type == 0) {
      discountAmount = item.price - discount.amount;
    }
  }
  let result = calculateTax(
    (item.price - discountAmount) * item.qty,
    merchant?.price_tax_include ?? false,
    tax
  );
  item.price_before_tax = result.priceBeforeTax;
  item.tax_amount = result.taxAmount;
  item.discount_percent = discountPercent;
  item.discount_amount = discountAmount;
  item.discount = discount;
  item.discount_id = discount?.uuid;

  return item;
};
export const countItemByQty = (
  qty: number,
  price: number,
  discount?: Discount | null,
  merchant?: Merchant | null,
  tax?: Tax | null
):  { priceBeforeTax: number; priceAfterTax: number; taxAmount: number } => {
  let discountAmount = 0;
  let discountPercent = 0;

  if (discount != null) {
    if (discount.type == 1) {
      discountAmount = (price * discount.percent) / 100;
      discountPercent = discount.percent;
    }
    if (discount.type == 0) {
      discountAmount = price - discount.amount;
    }
  } else {
    // console.log("TIDAK ADA DISCOUNT")
  }
  let result = calculateTax(
    (price - discountAmount) * qty,
    merchant?.price_tax_include ?? false,
    tax
  );
  return result
};

export const calculateTax = (
  price: number,
  includeTax: boolean,
  selectedTax?: Tax | null
): { priceBeforeTax: number; priceAfterTax: number; taxAmount: number } => {
  if (selectedTax == null)
    return { priceBeforeTax: price, priceAfterTax: price, taxAmount: 0 };
  if (includeTax) {
    return {
      priceAfterTax: price,
      taxAmount: (selectedTax!.amount / (100 + selectedTax!.amount)) * price,
      priceBeforeTax: (100 / (100 + selectedTax!.amount)) * price,
    };
  }
  return {
    priceBeforeTax: price,
    priceAfterTax: ((100 + selectedTax!.amount) / 100) * price,
    taxAmount: (selectedTax!.amount / 100) * price,
  };
};
export const countTax = (
  salesItems: MerchantSaleItem[],
  merchant?: Merchant | null,
  tax?: Tax | null
): number => {
  if (merchant && tax) {
    let result = calculateTax(
      countSubTotal(salesItems) - countDiscount(salesItems),
      merchant?.price_tax_include ?? false,
      tax
    );
    // console.log(tax)
    return result.taxAmount;
  }
  return 0;
};

export const countSubTotal = (salesItems: MerchantSaleItem[]): number => {
  return salesItems
    .filter((e) => e.status != "Cancel")
    .map((e) => {
      return e.price * e.qty;
    })
    .reduce((a, b) => a + b, 0);
};
export const countDiscount = (salesItems: MerchantSaleItem[]): number => {
  return salesItems
    .filter((e) => e.status != "Cancel")
    .map((e) => (e.discount_amount ?? 0) * e.qty)
    .reduce((a, b) => a + b, 0);
};

export const countGrandTotal = (
  salesItems: MerchantSaleItem[],
  merchant?: Merchant | null,
  tax?: Tax | null
): number => {
  let result = calculateTax(
    countSubTotal(salesItems) - countDiscount(salesItems),
    merchant?.price_tax_include ?? false,
    tax
  );
  return result.priceAfterTax;
};

// export const checkDiscounts = (
//   discounts: Discount[],
//   item: MerchantSaleItem
// ): Discount[] => {
//   return discounts.filter(
//     (e) => e.min_qty <= item.qty && e.max_qty >= item.qty && e.is_all_product
//   );
// };


export const checkDiscounts = (discounts: Discount[],
  item: MerchantSaleItem,
  customer?: Customer | null
): Discount[] => {
  return discounts.filter(
    (e) => {
      if (!item.product) return false
      let isAllProduct = e.min_qty <= item.qty && e.max_qty >= item.qty && e.products.map(e => e.uuid).includes(item.product?.uuid!)
      let isAllCustomer = e.is_all_customer ? true : e.min_qty <= item.qty && e.max_qty >= item.qty && (customer && e.customers.map(e => e.uuid).includes(customer.uuid))

      return isAllProduct && isAllCustomer
    }
  );
};
export const checkDiscountByProductId = (discounts: Discount[],
  productId: string,
  qty: number,
  customer?: Customer | null
): Discount[] => {
  return discounts.filter(
    (e) => {
      let isAllProduct = e.min_qty <= qty && e.max_qty >= qty && e.products.map(e => e.uuid).includes(productId)
      let isAllCustomer = e.is_all_customer ? true : e.min_qty <= qty && e.max_qty >= qty && (customer && e.customers.map(e => e.uuid).includes(customer.uuid))

      return isAllProduct && isAllCustomer
    }
  );
};