import Konva from "konva";
import moment from "moment";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type FC,
} from "react";
import { AiOutlineShop } from "react-icons/ai";
import { MdOutlineShoppingBag, MdPayment } from "react-icons/md";
import { TbArmchair } from "react-icons/tb";
import { Layer, Stage } from "react-konva";
import Moment from "react-moment";
import { Br, Line, Printer, Row, Text, render } from "react-thermal-printer";
import {
  Button,
  Divider,
  Input,
  Modal,
  Avatar as RSAvatar,
  SelectPicker,
  Tabs,
  TagPicker,
  Pagination as RsPagination,
} from "rsuite";
import DateRangePicker, { DateRange } from "rsuite/esm/DateRangePicker";
import Swal from "sweetalert2";
import { Discount } from "../model/discount";
import {
  Merchant,
  MerchantRoom,
  MerchantStation,
  TableOccupied,
} from "../model/merchant";
import { Pagination } from "../model/pagination";
import {
  MerchantOrder,
  MerchantSaleItem,
  MerchantStationOrder,
} from "../model/pos";
import { PriceLevelData, Product, VarianStock } from "../model/product";
import { Shape } from "../model/shape";
import { Tax } from "../model/tax";
import { SELECTED_STATION_ID } from "../objects/constants";
import { LoadingContext } from "../objects/loading_context";
import { RenderShape } from "../pages/RenderShape";
import {
  addItemPosOrder,
  addPosOrder,
  getPosDiscounts,
  getPosOrders,
  getPosRoom,
  getPosRoomDetail,
  getPosStation,
  getPosStationOrder,
  roomTableOccupy,
  roomTableOccupyDetail,
  splitPosOrder,
  updateItemPosStationOrder,
  updateRoomTableOccupy,
  updateStatusPosStationOrder,
} from "../repositories/pos";
import { getTaxDetail } from "../repositories/tax";
import {
  asyncLocalStorage,
  confirmDelete,
  convertHexToRGBA,
  getStorageDateRange,
  initials,
  invertColor,
  nl2br,
  randomStr,
  reverseArr,
  setNullString,
  setStorageDateRange,
  sleep,
} from "../utils/helper";
import { errorToast, successToast } from "../utils/helper-ui";
import { money } from "../utils/number";
import { countItem } from "../utils/pos_helper";
import InlineForm from "./inline_form";
import RestoOrder from "./RestoOrder";
import RestoPayment from "./RestoPayment";
import { getCustomers } from "../repositories/customer";
import { Customer } from "../model/customer";
interface RestoFlowProps {
  merchant: Merchant;
}

const RestoFlow: FC<RestoFlowProps> = ({ merchant }) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [rooms, setRooms] = useState<MerchantRoom[]>([]);
  const [salesItems, setSalesItems] = useState<MerchantSaleItem[]>([]);
  const [orders, setOrders] = useState<MerchantOrder[]>([]);
  const [stations, setStations] = useState<MerchantStation[]>([]);
  const [stationOrders, setStationOrders] = useState<MerchantStationOrder[]>(
    []
  );
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedShape, setSelectedShape] = useState<Shape | null>(null);
  const [selectedRoomId, setSelectedRoomId] = useState<string>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedOrder, setSelectedOrder] = useState<MerchantOrder>();
  const [selectedStationID, setSelectedStationID] = useState<string | null>(
    null
  );
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string[]>();
  const [selectedStationOrder, setSelectedStatusOrder] =
    useState<MerchantStationOrder>();
  const [tableOccupied, setTableOccupied] = useState<TableOccupied>();
  const stageCanvasRef = useRef<HTMLDivElement>(null);
  const layeRef = useRef<Konva.Layer>(null);
  const [room, setRoom] = useState<MerchantRoom>();
  const [tax, setTax] = useState<Tax | null>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  // const [orderItemQty, setOrderItemQty] = useState(0);
  const [mountQty, setMountQty] = useState(true);
  const [showPayment, setShowPayment] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOccupiedOpen, setModalOccupiedOpen] = useState(false);
  const [modalOrderOpen, setModalOrderOpen] = useState(false);
  const [modalOrderDetailOpen, setModalOrderDetailOpen] = useState(false);
  const [modalStatusOrderOpen, setModalStatusOrderOpen] = useState(false);
  const [activeKey, setActiveKey] = useState("room");
  const [status, setStatus] = useState("Occuped");
  const [stationRemarks, setStationRemarks] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [newCustomerName, setNewCustomerName] = useState("");
  const [orderItemNotes, setOrderItemNotes] = useState("");
  const [notes, setNotes] = useState("");
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [showSplitBill, setShowSplitBill] = useState(false);
  const [splitBillItems, setSplitBillItems] = useState<MerchantSaleItem[]>([]);
  const [pageOrder, setPageOrder] = useState(1);
  const [limitOrder, setLimitOrder] = useState(20);
  const [searchOrder, setSearchOrder] = useState("");
  const [paginationOrder, setPaginationOrder] = useState<Pagination | null>(
    null
  );
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const clearMountQty = () => {
    // console.log("clear")
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 50);
  };

  useEffect(() => {
    setMounted(true);
    getStorageDateRange().then((v) => {
      if (!mounted && v) {
        // console.log(v);
        setDateRange([moment(v[0]).toDate(), moment(v[1]).toDate()]);
      }
      setMounted(true);
    });
  }, []);
  useLayoutEffect(() => {
    // if (stageCanvasRef?.current) {
    //   setWidth(stageCanvasRef?.current?.clientWidth);
    //   setHeight(stageCanvasRef?.current?.clientHeight);
    // }
  }, [shapes]);

  useEffect(() => {
    getPosRoom()
      .then((e) => e.json())
      .then((e) => {
        setRooms(e.data);
      });

    getPosDiscounts()
      .then((v) => v.json())
      .then((v) => setDiscounts(v.data))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });

    if (merchant && merchant.tax_id) {
      //   console.log(merchant);
      getTaxDetail(merchant.tax_id)
        .then((v) => v.json())
        .then((v) => {
          setTax(v.data);
        });
    }
    if (merchant) {
      getPosStation()
        .then((v) => v.json())
        .then((v) => {
          setStations(v.data);
        });

      asyncLocalStorage.getItem(SELECTED_STATION_ID).then((v) => {
        setSelectedStationID(v);
        getPosStationOrder(v)
          .then((v) => v.json())
          .then((v) => {
            setStationOrders(v.data);
          });
      });
    }
  }, [merchant, selectedStationID, selectedOrderStatus]);

  useEffect(() => {
    if (customerName) {
      getCustomers({ page: 1, limit: 0, search: customerName })
        .then((v) => v.json())
        .then((v) => {
          setCustomers(v.data);
        });
    } else {
      setCustomers([]);
    }
  }, [customerName]);
  useEffect(() => {
    getOrders();
  }, [dateRange, pageOrder, limitOrder, searchOrder]);
  useEffect(() => {
    if (!merchant) return;
    if (!mounted) return;
    if (!websocket) {
      var url = `${process.env.REACT_APP_API_URL}/ws/merchant/${merchant.uuid}`;
      var ws = new WebSocket(url);
      setWebsocket(ws);
      ws.onopen = (msg) => {
        // const message = JSON.stringify({ type: "greeting", content: "Hello" });
        // ws.send(message);
      };

      ws.onmessage = (msg: any) => {
        const data = JSON.parse(msg.data);
        // console.log(data);
        if (
          data.type == "MERCHANT_SALE_PAID" ||
          data.type == "EDIT_MERCHANT_ORDER_ITEM" ||
          data.type == "CANCEL_MERCHANT_ORDER_ITEM"
        ) {
          if (data.message) {
            successToast(data.message);
          }
        }
        if (
          data.type == "ADD_MERCHANT_STATION_ORDER" ||
          data.type == "UPDATE_MERCHANT_STATION_ORDER_ITEM_STATUS" ||
          data.type == "UPDATE_MERCHANT_STATION_ORDER_STATUS"
        ) {
          if (data.message) {
            successToast(data.message);
          }
          asyncLocalStorage.getItem(SELECTED_STATION_ID).then((v) => {
            getPosStationOrder(v)
              .then((v) => v.json())
              .then((v) => {
                setStationOrders(v.data);
                setSelectedStatusOrder((val) => {
                  if (val) {
                    let newOrder = (v.data as MerchantStationOrder[]).find(
                      (e) => e.uuid == val?.uuid
                    );
                    setSelectedStatusOrder(newOrder);
                  }
                  return val;
                });
              });
          });
        }
      };
    }
  }, [merchant, mounted]);

  const getOrders = () => {
    if (!dateRange) return;
    getPosOrders(
      {
        page: pageOrder,
        limit: limitOrder,
        search: searchOrder,
        status: selectedOrderStatus ? selectedOrderStatus.join(",") : undefined,
      },
      dateRange
    )
      .then((v) => v.json())
      .then((v) => {
        setOrders(v.data);
        setPaginationOrder(v.meta);
      });
  };

  useEffect(() => {
    // console.log(selectedRoomId);
    if (selectedRoomId) {
      getPosRoomDetail(selectedRoomId)
        .then((e) => e.json())
        .then((e) => {
          setRoom(e.data);
        });
    }
  }, [selectedRoomId]);

  useEffect(() => {
    if (room) {
      setShapes(
        room.tables.map((e) => {
          if (e.status == "Open") {
            e.fill = "#ffffff";
            e.textColor = "#333333";
          }
          if (e.status == "Booked") {
            e.fill = convertHexToRGBA(e.fill, 0.2);
            e.textColor = invertColor(e.textColor);
          }
          return e;
        })
      );
    }
  }, [room]);

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmptyArea = e.target === e.target.getStage();
    if (clickedOnEmptyArea) {
      setSelectedId(null);
      setSelectedShape(null);
    } else {
    }
  };

  const renderRoom = () => (
    <div className="p-2 table-viewer" ref={stageCanvasRef}>
      <Stage
        width={width ?? 800}
        height={height ?? 600}
        style={{ background: "white" }}
        onClick={checkDeselect}
      >
        <Layer ref={layeRef}>
          {shapes.map((shape, i) => {
            return (
              <RenderShape
                key={i}
                isEditable={false}
                width={width ?? 600}
                height={height ?? 600}
                isSelected={selectedId == shape.id}
                shape={shape}
                onTap={(shape) => {
                  if (shape.status == "Open") {
                    setSelectedShape(shape);
                    setModalOpen(true);
                  } else {
                    roomTableOccupyDetail(room!.uuid!, shape.occupy_id!)
                      .then((e) => e.json())
                      .then((e) => {
                        setTableOccupied(e.data);
                        setModalOccupiedOpen(true);
                      });
                  }
                  //   console.log(room?.tables.find(e => e.id == shape.id))
                }}
                onChange={(shape) => {
                  const newShapes = shapes.map((s) => {
                    if (s.id === shape.id) {
                      setSelectedShape(shape);
                      return shape;
                    }
                    return s;
                  });
                  setShapes(newShapes);
                }}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
  const renderRooms = () => (
    <div className="p-2 table-viewer">
      {rooms.map((e) => (
        <div
          key={e.uuid}
          className="m-2  w-[250px] bg-white border border-gray-300 rounded-lg shadow-sm cursor-pointer"
          onClick={() => {
            setSelectedRoomId(e.uuid);
          }}
        >
          <img
            src={e.picture}
            className="w-full h-[120px] object-cover rounded-t-lg"
            alt=""
          />
          <div className="p-2">
            <h3 className="text-lg font-bold">{e.name}</h3>
            <p className="text-sm">{e.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  const addItem = (
    item: Product,
    varian?: VarianStock | null,
    discount?: Discount | null,
    orderItemQty?: number
  ) => {
    let itemExist = salesItems.find((e) => e.product.uuid == item.uuid);
    if (item.varians.length > 0 && varian != null) {
      itemExist = salesItems.find(
        (e) => e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid
      );
    }
    let qty = orderItemQty ?? 0;
    let saleItem: MerchantSaleItem = {
      uniqueId: randomStr(3),
      product: item,
      qty: qty,
      discount: discount,
      discount_id: discount?.uuid,
      isService: false,
      price: item.prices[0].amount,
      varian: varian,
      tax_id: tax?.uuid,
      tax: tax,
      notes: orderItemNotes,
    };

    if (!itemExist) {
      salesItems.push(countItem(saleItem, discount, merchant, tax));

      setSalesItems([...salesItems]);
    } else {
      Swal.fire({
        title: "Tambah Item",
        text: "Apakah Anda ingin menambahkan item baru atau yang sudah ada?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Item Baru",
        cancelButtonText: "Item yang Ada",
      }).then((result) => {
        if (result.isConfirmed) {
          salesItems.push(countItem(saleItem, discount, merchant, tax));
          setSalesItems([...salesItems]);
        } else if (result.isDismissed) {
          let newItems = salesItems.map((e) => {
            if (item.varians.length > 0 && varian != null) {
              if (
                e.product.uuid == item.uuid &&
                e.varian?.uuid == varian.uuid
              ) {
                e.qty = e.qty + (orderItemQty ?? 0);
                e = countItem(e, discount, merchant, tax);
              }
            } else {
              if (e.product.uuid == item.uuid) {
                e.qty = e.qty + (orderItemQty ?? 0);
                e = countItem(e, discount);
              }
            }
            e.notes = orderItemNotes;
            return e;
          });

          setSalesItems([...newItems]);
        }
      });
    }

    // setMountQty(false);
    // setTimeout(() => {
    //   setMountQty(true);
    // }, 10);
  };

  const delItem = (item: MerchantSaleItem) => {
    let newItems = salesItems.filter((e) => e.uniqueId != item.uniqueId);
    setSalesItems([...newItems]);
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 10);
  };

  const printReceipt = async () => {
    try {
      let mobileNavigatorObject: any = window.navigator;

      if (mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
        const device = await mobileNavigatorObject.bluetooth.requestDevice({
          filters: [{ services: ["000018f0-0000-1000-8000-00805f9b34fb"] }],
        });

        const server = await device.gatt.connect();
        const service = await server.getPrimaryService(
          "000018f0-0000-1000-8000-00805f9b34fb"
        );

        const characteristic = await service.getCharacteristic(
          "00002af1-0000-1000-8000-00805f9b34fb"
        );
        const receipt = (
          <Printer type="epson" width={32} debug={true}>
            <Text size={{ width: 2, height: 2 }}>9,500</Text>
            <Br />
            <Br />
            <Br />
            <Br />
          </Printer>
        );

        const bytes = await renderReceiptData(32);
        // characteristic.writeValue(data)

        const MAX_LENGTH = 64;
        for (let i = 0; i < bytes.byteLength; i += MAX_LENGTH) {
          const end =
            i + MAX_LENGTH > bytes.byteLength
              ? bytes.byteLength
              : i + MAX_LENGTH;
          const chunk = bytes.slice(i, end);
          await characteristic.writeValueWithoutResponse(chunk);
          await sleep(50);
        }
      }
    } catch (error) {
      errorToast(`${error}`);
    }
  };

  const renderReceiptData = async (paperWidth: number): Promise<Uint8Array> => {
    const receipt = (
      <Printer type="epson" width={paperWidth} debug={true}>
        {/* {merchant?.logo && <Image src={merchant.logo} width={48} align="center" transforms={[transforms.floydSteinberg]}  />} */}
        <Line />
        <Row
          left={<Text>Room.</Text>}
          right={
            <Text>
              {
                selectedStationOrder?.merchant_order?.merchant_table_occupancy
                  ?.room?.name
              }
            </Text>
          }
        />
        <Row
          left={<Text>Table.</Text>}
          right={
            <Text>
              {
                selectedStationOrder?.merchant_order?.merchant_table_occupancy
                  ?.table_alias
              }
            </Text>
          }
        />
        <Row
          left={<Text>Cust.</Text>}
          right={
            <Text>{selectedStationOrder?.merchant_order.customer_name}</Text>
          }
        />
        <Line />
        <Row left={<Text bold>Item</Text>} right={<Text bold>Jumlah</Text>} />
        <Line />
        {(selectedStationOrder?.order_detail ?? []).map((item) => {
          let desc = ` ${item.description}`;
          let qty = `${item.qty} x ${item.unit_name}`;
          return <Row gap={1} key={item.uuid} left={desc} right={qty} />;
        })}
        <Line />

        <Br />
        <Br />
        <Br />
      </Printer>
    );

    return await render(receipt);
  };

  const editItem = (
    item: MerchantSaleItem,
    qty: number,
    refresh?: boolean,
    discount?: Discount | null
  ) => {
    let newItems = salesItems.map((e) => {
      if (item.uniqueId == e.uniqueId) {
        if (item.product.prices[0].price_level_data.length > 0) {
          let levels: PriceLevelData[] = reverseArr(
            item.product.prices[0].price_level_data
          );
          for (const key in levels) {
            if (Object.prototype.hasOwnProperty.call(levels, key)) {
              const element = levels[key];
              if (qty >= element.min) {
                e.price = element.amount;
                // console.log(element.min)
                break;
              }
            }
          }
        }
        e.qty = qty;
        e = countItem(e, discount);
      }
      return e;
    });
    setSalesItems([...newItems]);
    if (refresh) {
      setMountQty(false);
      setTimeout(() => {
        setMountQty(true);
      }, 10);
    }
  };
  return (
    <div className="w-full h-full bg-white">
      <Tabs
        defaultActiveKey="room"
        activeKey={activeKey}
        vertical
        appearance="tabs"
        onSelect={(key, ev) => {
          if (key == "room") {
            setRoom(undefined);
            setSelectedRoomId("");
            setSelectedShape(null);
          }
          setActiveKey(key!);
        }}
      >
        <Tabs.Tab
          eventKey="room"
          title={
            <div className="flex flex-col justify-center items-center space-y-1 w-[80px]">
              <TbArmchair size={36} />
              <span className="text-xs">Ruangan</span>
            </div>
          }
        >
          {!room && renderRooms()}
          {room && renderRoom()}
        </Tabs.Tab>
        <Tabs.Tab
          eventKey="order"
          title={
            <div className="flex flex-col justify-center items-center space-y-1 w-[80px]">
              <MdOutlineShoppingBag size={36} />
              <span className="text-xs">Pesanan</span>
            </div>
          }
        >
          <div className="table-viewer">
            <div className="flex flex-row justify-end pt-4 pr-4 space-x-2 pb-2 border-b  overflow-hidden">
              <DateRangePicker
                format="dd MMM yyyy, HH:mm"
                value={dateRange}
                onChange={(value) => {
                  setDateRange(value!);
                  if (value) setStorageDateRange(value);
                }}
                onClean={() => {
                  setDateRange([
                    moment().startOf("day").toDate(),
                    moment().endOf("day").toDate(),
                  ]);
                }}
              />

              <TagPicker
                data={[
                  { label: "Active", value: "Active" },
                  { label: "PAID", value: "PAID" },
                  { label: "Cancel", value: "Cancel" },
                ]}
                labelKey="label"
                valueKey="value"
                placeholder="Pilih Status"
                className="w-[300px]"
                value={selectedOrderStatus}
                onChange={(value) => {
                  setSelectedOrderStatus(value!);
                }}
                onClean={() => {
                  setSelectedOrderStatus(undefined);
                }}
              />
            </div>
            <div
              className="flex flex-row "
              style={{ height: "calc(100vh - 160px)" }}
            >
              <RestoOrder
                modalOrderOpen={modalOrderOpen}
                setModalOrderOpen={setModalOrderOpen}
                merchant={merchant}
                salesItems={salesItems}
                tableOccupied={tableOccupied}
                setTableOccupied={setTableOccupied}
                discounts={discounts}
                orders={orders}
                isLoading={isLoading}
                delItem={delItem}
                addItem={addItem}
                editItem={editItem}
                setActiveKey={setActiveKey}
                setSelectedOrder={(val) => {
                  getOrders();
                  setSelectedOrder(val);
                  setMounted(false);
                  setTimeout(() => {
                    setMounted(true);
                  }, 100);
                }}
                selectedOrder={selectedOrder}
                resetOrders={() => {
                  getOrders();
                }}
                sendOrder={() => {
                  setIsLoading(true);
                  // console.log(selectedOrder)
                  // return
                  if (!selectedOrder) {
                    addPosOrder({
                      merchant_table_occupancy_id: setNullString(
                        tableOccupied?.uuid
                      ),
                      customer_id: setNullString(tableOccupied?.customer_id),
                      data: salesItems,
                    })
                      .then(() => {
                        setModalOrderOpen(false);
                        setSalesItems([]);

                        getOrders();
                      })
                      .catch(errorToast)
                      .finally(() => {
                        setIsLoading(false);
                      });
                  } else {
                    addItemPosOrder(selectedOrder!.uuid, {
                      merchant_table_occupancy_id: setNullString(
                        tableOccupied?.uuid
                      ),
                      customer_id: setNullString(tableOccupied?.customer_id),
                      data: salesItems,
                    })
                      .then(() => {
                        setModalOrderOpen(false);
                        setSalesItems([]);
                        getOrders();
                        setSelectedOrder(undefined);
                      })
                      .catch(errorToast)
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }
                }}
                splitOrder={async (name: string, items: MerchantSaleItem[]) => {
                  try {
                    setIsLoading(true);
                    const res = await splitPosOrder(selectedOrder!.uuid, {
                      customer_name: name,
                      original: [
                        ...(selectedOrder?.data ?? [])
                          .map((e) => {
                            return {
                              ...e,
                              qty:
                                e.qty -
                                items.find((s) => s.uniqueId == e.uniqueId)!
                                  .qty,
                            };
                          })
                          .filter((e) => e.qty > 0),
                      ],
                      new: items
                        .map((e) => {
                          return {
                            ...e,
                            uniqueId: randomStr(3),
                          };
                        })
                        .filter((e) => e.qty > 0),
                    });
                    successToast("Pesanan berhasil dibagi");
                    getOrders();
                    setModalOrderDetailOpen(false);
                    return true;
                  } catch (error) {
                    errorToast(`error: ${error}`);
                    return false;
                  } finally {
                    setIsLoading(false);
                  }
                }}
                pagination={
                  <RsPagination
                    prev
                    last
                    next
                    first
                    size="xs"
                    maxButtons={7}
                    total={paginationOrder?.total_records ?? 0}
                    limit={limitOrder ?? 10}
                    activePage={pageOrder}
                    onChangePage={setPageOrder}
                  />
                }
              />
            </div>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          eventKey="station"
          title={
            <div className="flex flex-col justify-center items-center space-y-1 w-[80px]">
              <AiOutlineShop size={36} />
              <span className="text-xs">Station</span>
            </div>
          }
        >
          <div className="w-full h-full table-viewer">
            <div className="flex flex-row justify-end pt-4 pr-4 space-x-2">
              <SelectPicker
                data={[
                  { label: "Pending", value: "Pending" },
                  { label: "On Progress", value: "On Progress" },
                  { label: "Waiting For Pickup", value: "Waiting For Pickup" },
                  { label: "Delivering", value: "Delivering" },
                  { label: "Canceled", value: "Canceled" },
                  { label: "Finished", value: "Finished" },
                ]}
                labelKey="label"
                valueKey="value"
                placeholder="Pilih Status"
                className="w-[160px]"
                value={selectedStatus}
                onChange={(value) => {
                  setSelectedStatus(value!);
                }}
                onClean={() => {
                  setSelectedStatus(undefined);
                }}
              />
              <SelectPicker
                data={stations}
                labelKey="name"
                valueKey="uuid"
                placeholder="Pilih Station"
                className="w-[160px]"
                value={selectedStationID}
                onChange={(value) => {
                  setSelectedStationID(value);
                  asyncLocalStorage.setItem(SELECTED_STATION_ID, value);
                }}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
              {stationOrders
                .filter((e) => {
                  if (selectedStatus === undefined) return true;
                  return e.status === selectedStatus;
                })
                .map((order) => (
                  <div
                    key={order.uuid}
                    className="bg-white rounded-lg shadow-md p-4"
                  >
                    <div className="flex justify-between">
                      <div className="flex items-center space-x-2">
                        <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden">
                          <img
                            src={
                              order.merchant_order?.merchant_table_occupancy
                                ?.room?.picture
                            }
                            alt="table"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <span className="text-lg font-medium">
                          {
                            order.merchant_order?.merchant_table_occupancy
                              ?.table_alias
                          }
                        </span>
                      </div>
                      <div className="flex  items-end space-y-2 flex-col ">
                        <span className="text-xs font-semibold">Station</span>
                        <span className="text-sm">
                          {order.merchant_station?.name}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row justify-between items-start">
                      <span className="text-sm font-medium">
                        {order.order_detail.length} items
                      </span>
                      <span>
                        <span
                          className={`${
                            order.status === "Pending"
                              ? "bg-red-600"
                              : order.status === "On Progress"
                              ? "bg-blue-600"
                              : order.status === "Waiting For Pickup"
                              ? "bg-orange-600"
                              : order.status === "Delivering"
                              ? "bg-violet-600"
                              : order.status === "Canceled"
                              ? "bg-gray-600"
                              : order.status === "Finished"
                              ? "bg-green-600"
                              : ""
                          } text-white px-2 py-1 rounded-lg text-xs`}
                        >
                          {order.status}
                        </span>
                      </span>
                    </div>
                    <div className="mt-4">
                      <Button
                        appearance="primary"
                        size="sm"
                        block
                        onClick={() => {
                          setModalStatusOrderOpen(true);
                          setSelectedStatusOrder(order);
                        }}
                      >
                        Detail
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          eventKey="payment"
          title={
            <div className="flex flex-col justify-center items-center space-y-1 w-[80px]">
              <MdPayment size={36} />
              <span className="text-xs">Pembayaran</span>
            </div>
          }
        >
          <div className="w-full h-full table-viewer">
            {selectedOrder && mounted && (
              <>
                <RestoPayment order={selectedOrder} merchant={merchant} />
              </>
            )}
          </div>
        </Tabs.Tab>
      </Tabs>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Pesan Meja {selectedShape?.text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Nama"}>
            <Input
              className="input-form"
              placeholder="Nama"
              name="name"
              value={customerName}
              onChange={(e) => setCustomerName(e)}
            />
            {customers.length > 0 && (
              <ul className="rounded-lg border mt-4 max-w-[160px]">
                {customers.map((e) => (
                  <li
                    key={e.uuid}
                    className="px-2 py-2 text-sm hover:bg-gray-100 cursor-pointer "
                    onClick={() => {
                      setCustomerName(e.name);
                      setSelectedCustomer(e);
                      setTimeout(() => {
                        setCustomers([]);
                      }, 100);
                    }}
                  >
                    {e.name} {e.address}
                  </li>
                ))}
              </ul>
            )}
          </InlineForm>
          <InlineForm title={"Tipe"}>
            <SelectPicker
              block
              data={[
                { value: "Occuped", label: "Okupansi" },
                { value: "Booked", label: "Booking" },
              ]}
              value={status}
              onChange={(e) => setStatus(e!)}
            />
          </InlineForm>
          <InlineForm title={"Catatan"} style={{ alignItems: "start" }}>
            <Input
              className="input-form"
              as={"textarea"}
              rows={9}
              placeholder="Catatan"
              value={notes}
              onChange={(e) => setNotes(e)}
            />
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            onClick={() => {
              if (!customerName) {
                errorToast("Nama tidak boleh kosong");
                return;
              }
              setIsLoading(true);
              roomTableOccupy(room?.uuid!, {
                table_alias: selectedShape?.text ?? "",
                table_code: selectedShape?.id ?? "",
                customer_name: customerName,
                customer_id: setNullString(selectedCustomer?.uuid),
                start_time: moment().toISOString(),
                status: status,
                notes: notes,
              })
                .then((e) => {
                  setCustomerName("");
                  setNotes("");
                  setModalOpen(false);
                  getPosRoomDetail(selectedRoomId!)
                    .then((e) => e.json())
                    .then((e) => {
                      setRoom(e.data);
                    });
                })
                .catch(errorToast)
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={modalOccupiedOpen}
        onClose={() => setModalOccupiedOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Detail {tableOccupied?.table_alias}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Nama Pelanggan"}>
            <span className="text-sm">
              {tableOccupied?.customer_name || ""}
            </span>
          </InlineForm>
          <InlineForm title={"Tgl/Waktu"}>
            <span className="text-sm">
              <Moment format="DD MMMM YYYY, HH:mm">
                {tableOccupied?.start_time}
              </Moment>
            </span>
          </InlineForm>
          <InlineForm title={"Status"}>
            <span className="text-sm">{tableOccupied?.status || ""}</span>
          </InlineForm>
          <InlineForm title={"Catatan"}>
            <span className="text-sm">{tableOccupied?.notes || ""}</span>
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          {tableOccupied?.status == "Booked" && (
            <>
              <Button
                appearance="subtle"
                onClick={() => {
                  confirmDelete(
                    () => {
                      setIsLoading(true);
                      updateRoomTableOccupy(room!.uuid!, tableOccupied?.uuid!, {
                        status: "Cancel",
                      })
                        .then((e) => {
                          setModalOccupiedOpen(false);
                          getPosRoomDetail(selectedRoomId!)
                            .then((e) => e.json())
                            .then((e) => {
                              setRoom(e.data);
                            });
                        })
                        .catch(errorToast)
                        .finally(() => setIsLoading(false));
                    },
                    null,
                    null,
                    "Ya Batalkan"
                  );
                }}
              >
                Batalkan Okupansi
              </Button>
              <Button
                appearance="primary"
                onClick={() => {
                  confirmDelete(
                    () => {
                      setIsLoading(true);
                      updateRoomTableOccupy(room!.uuid!, tableOccupied?.uuid!, {
                        status: "Occuped",
                      })
                        .then((e) => {
                          setModalOccupiedOpen(false);
                          getPosRoomDetail(selectedRoomId!)
                            .then((e) => e.json())
                            .then((e) => {
                              setRoom(e.data);
                            });
                        })
                        .catch(errorToast)
                        .finally(() => setIsLoading(false));
                    },
                    null,
                    null,
                    "Ya Lanjutkan"
                  );
                }}
              >
                Tandai Terisi
              </Button>
            </>
          )}
          {!tableOccupied?.order ? (
            <Button
              appearance="primary"
              onClick={() => {
                setActiveKey("order");
                setModalOccupiedOpen(false);
                setModalOrderOpen(true);
                setSelectedOrder(undefined);
              }}
            >
              Buat Pesanan
            </Button>
          ) : (
            <Button
              appearance="primary"
              color="orange"
              onClick={() => {
                confirmDelete(
                  () => {
                    setIsLoading(true);
                    updateRoomTableOccupy(room!.uuid!, tableOccupied?.uuid!, {
                      status: "Closed",
                    })
                      .then((e) => {
                        setModalOccupiedOpen(false);
                        getPosRoomDetail(selectedRoomId!)
                          .then((e) => e.json())
                          .then((e) => {
                            setRoom(e.data);
                          });
                      })
                      .catch(errorToast)
                      .finally(() => setIsLoading(false));
                  },
                  null,
                  null,
                  "Ya Lanjutkan"
                );
              }}
            >
              Selesai
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        open={modalStatusOrderOpen}
        onClose={() => setModalStatusOrderOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>
            Detail Pesanan {selectedStationOrder?.merchant_station?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {selectedStationOrder?.order_detail!.map((e) => (
              <li
                key={e.uniqueId}
                className="flex flex-row space-x-2 mb-4 w-full"
              >
                <RSAvatar
                  src={e.product?.default_picture}
                  alt={initials(e.product?.name)}
                  circle
                  color="violet"
                />
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-col">
                    <h3>{e.product?.name}</h3>
                    <small>{e.notes}</small>
                    {e.status != "Done" && (
                      <div
                        className=" w-24 bg-blue-400 hover:bg-blue-600 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg"
                        onClick={() => {
                          updateItemPosStationOrder(
                            selectedStationOrder!.uuid,
                            e.uniqueId
                          );
                        }}
                      >
                        Tandai Selesai
                      </div>
                    )}
                    {e.status == "Done" && (
                      <div className=" w-24 bg-green-600 hover:bg-green-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg">
                        Selesai
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <h3 className="text-4xl text-orange-400">{money(e.qty)}</h3>
                    {e.unit_name && <small>{e.unit_name}</small>}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Divider />
          <InlineForm title={"Status"}>
            <span
              className={`${
                selectedStationOrder?.status === "Pending"
                  ? "bg-red-600"
                  : selectedStationOrder?.status === "On Progress"
                  ? "bg-blue-600"
                  : selectedStationOrder?.status === "Waiting For Pickup"
                  ? "bg-orange-600"
                  : selectedStationOrder?.status === "Delivering"
                  ? "bg-violet-600"
                  : selectedStationOrder?.status === "Canceled"
                  ? "bg-gray-600"
                  : selectedStationOrder?.status === "Finished"
                  ? "bg-green-600"
                  : ""
              } text-white px-2 py-1 rounded-lg text-xs`}
            >
              {selectedStationOrder?.status}
            </span>
          </InlineForm>
          <InlineForm title={"Catatan"} style={{ alignItems: "start" }}>
            <div
              className="flex flex-col text-sm mb-4 -mt-4"
              dangerouslySetInnerHTML={{
                __html: nl2br(selectedStationOrder?.remarks),
              }}
            />

            <Input
              as="textarea"
              placeholder="Masukkan catatan"
              value={stationRemarks}
              onChange={(e) => setStationRemarks(e)}
            />
          </InlineForm>
          <div className="grid grid-cols-3 gap-2">
            <div>
              {selectedStationOrder?.status == "Pending" && (
                <Button
                  className="w-full"
                  appearance="primary"
                  color="blue"
                  onClick={() => {
                    updateStatusPosStationOrder(
                      selectedStationOrder?.uuid!,
                      "On Progress",
                      stationRemarks
                    ).then(() => {
                      setStationRemarks("");
                    });
                  }}
                >
                  Proses
                </Button>
              )}
              {selectedStationOrder?.status == "On Progress" && (
                <Button
                  className="w-full"
                  appearance="primary"
                  color="orange"
                  onClick={() => {
                    updateStatusPosStationOrder(
                      selectedStationOrder?.uuid!,
                      "Waiting For Pickup",
                      stationRemarks
                    ).then(() => {
                      setStationRemarks("");
                    });
                  }}
                >
                  Siap Di PickUp
                </Button>
              )}
              {selectedStationOrder?.status == "Waiting For Pickup" && (
                <Button
                  className="w-full"
                  appearance="primary"
                  color="violet"
                  onClick={() => {
                    updateStatusPosStationOrder(
                      selectedStationOrder?.uuid!,
                      "Delivering",
                      stationRemarks
                    ).then(() => {
                      setStationRemarks("");
                    });
                  }}
                >
                  Kirim ke Pelanggan
                </Button>
              )}
              {selectedStationOrder?.status == "Delivering" && (
                <Button
                  className="w-full"
                  appearance="primary"
                  color="green"
                  onClick={() => {
                    updateStatusPosStationOrder(
                      selectedStationOrder?.uuid!,
                      "Finished",
                      stationRemarks
                    ).then(() => {
                      setStationRemarks("");
                    });
                  }}
                >
                  Terkirim Pelanggan
                </Button>
              )}
            </div>
            <Button
              className="w-full"
              appearance="ghost"
              color="green"
              onClick={() => {
                updateStatusPosStationOrder(
                  selectedStationOrder?.uuid!,
                  selectedStationOrder?.status!,
                  stationRemarks
                ).then(() => {
                  setStationRemarks("");
                });
              }}
            >
              Kirim Pesan
            </Button>
            <Button
              className="w-full"
              appearance="ghost"
              color="blue"
              onClick={() => printReceipt()}
            >
              Print
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default RestoFlow;
