import { useContext, useEffect, useRef, useState, type FC } from "react";
import { Merchant } from "../model/merchant";
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  QrCodeIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import { Message, Modal, toaster, Tooltip, Whisper } from "rsuite";
import {
  Product,
  ProductCategory,
  ProductStock,
  ProductStoreStock,
  VarianStock,
} from "../model/product";
import { money } from "../utils/number";
import { Discount } from "../model/discount";
import ModalSearch from "./modals/modal_search";
import ModalCustom from "./modals/modal_custom";
import {
  getPosDiscounts,
  getPosProductByBarcode,
  getPosProductCategories,
  getPosProducts,
  getPosSaleDetail,
  sendPosSale,
} from "../repositories/pos";
import { errorToast } from "../utils/helper-ui";
import { LoadingContext } from "../objects/loading_context";
import { getCirculationStock } from "../repositories/circulation";
import { getProductStocks } from "../repositories/product";
import Avatar from "./avatar";
import { parseVarian } from "../utils/helper";
interface OrderProductProps {
  merchant?: Merchant | null;
  addItem: (
    item: Product,
    varian?: VarianStock | null,
    discount?: Discount | null
  ) => void;
}

const OrderProduct: FC<OrderProductProps> = ({ merchant, addItem }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [modalSearchOpen, setModalSearchOpen] = useState(false);
  const [modalBarcodeOpen, setModalBarcodeOpen] = useState(false);
  const barcodeRef = useRef<HTMLInputElement>(null);
  const [searchProducts, setSearchProducts] = useState<Product[]>([]);
  const [mounted, setMounted] = useState(false);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalVarianOpen, setModalVarianOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedVarians, setSelectedVarians] = useState<VarianStock[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );
  const [varianStocks, setVarianStocks] = useState<ProductStoreStock[]>([]);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    getPosProductCategories()
      .then((v) => v.json())
      .then((v) => setProductCategories(v.data))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });

    getPosProducts({ is_pinned: true })
      .then((v) => v.json())
      .then((v) => setProducts(v.data))
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
  }, [mounted]);

  useEffect(() => {
    if (categoryId == "") {
      getPosProducts({ is_pinned: true })
        .then((v) => v.json())
        .then((v) => setProducts(v.data));
      return;
    }
    getPosProducts({ product_category_id: categoryId })
      .then((v) => v.json())
      .then((v) => setProducts(v.data));
  }, [categoryId]);

  useEffect(() => {
    if (search != "") {
      getPosProducts({ search })
        .then((v) => v.json())
        .then((v) => setSearchProducts(v.data));
    } else {
      setSearchProducts([]);
    }
  }, [search]);

  return (
    <div className="flex-col flex h-full">
      <div className="flex justify-between p-4">
        <h3 className=" text-xl font-semibold mb-2 ">Kategori </h3>
        <div className="flex">
          <MagnifyingGlassIcon
            className="w-4 mr-2 transition-all hover:w-8 text-gray-600 hover:text-gray-800 ml-2 cursor-pointer"
            onClick={() => setModalSearchOpen(true)}
          />
          <Whisper followCursor speaker={<Tooltip>Scan Barcode</Tooltip>}>
            <QrCodeIcon
              className="w-4 transition-all hover:w-8 text-gray-600 hover:text-gray-800 ml-2 cursor-pointer"
              onClick={() => {
                setModalBarcodeOpen(true);
                setTimeout(() => {
                  barcodeRef.current?.focus();
                }, 1000);
              }}
            />
          </Whisper>
        </div>
      </div>
      <ul className=" overflow-y-auto  whitespace-nowrap pb-2">
        <li
          onClick={() => {
            setCategoryId("");
          }}
          className={`inline-block pos-product-cat ${
            categoryId == "" ? "active" : ""
          }`}
        >
          Semua Produk
        </li>
        {productCategories.map((e) => (
          <li
            onClick={() => {
              setCategoryId(e.uuid);
            }}
            key={e.uuid}
            className={` inline-block pos-product-cat ${
              categoryId == e.uuid ? "active" : ""
            }`}
          >
            {e.name}
          </li>
        ))}
      </ul>
      <div className="flex-1 ">
        <div className=" grid grid-cols-5 gap-x-6 gap-y-2 px-6 mt-2 overflow-y-auto flex-1">
          {products.map((e) => (
            <div
              onClick={async () => {
                if (e.varians.length > 0) {
                  if (e.varians.length == 1) {
                    getCirculationStock(e.uuid, merchant!.default_store_id!)
                      .then((v) => v.json())
                      .then((v) => {
                        let selected = (v.data as ProductStoreStock[]).find(
                          (vr) => vr.varian_id == e.varians[0].uuid
                        );
                        if (!selected) {
                          toaster.push(
                            <Message showIcon type={"warning"} closable>
                              Total Stok 0 {e.unit_name}
                            </Message>,
                            { placement: "bottomStart", duration: 3000 }
                          );
                        } else {
                          if (selected.amount == 0) {
                            toaster.push(
                              <Message showIcon type={"warning"} closable>
                                Total Stok 0 {e.unit_name}
                              </Message>,
                              { placement: "bottomStart", duration: 3000 }
                            );
                          }
                        }

                        addItem(e, e.varians[0]);
                      });
                    return;
                  }
                  setSelectedProduct(e);
                  setModalVarianOpen(true);
                } else {
                  let res = await getProductStocks(e.uuid);
                  let resJson = await res.json();
                  if (merchant != null && merchant!.check_stock) {
                    let productStocks: ProductStock[] = resJson.data;
                    let sel = productStocks.find(
                      (e) => e.uuid == merchant.default_store_id
                    );
                    if (sel) {
                      if (sel.stock == 0) {
                        toaster.push(
                          <Message showIcon type={"warning"} closable>
                            Total Stok {sel.stock} {e.unit_name}
                          </Message>,
                          { placement: "bottomStart", duration: 3000 }
                        );
                      }
                    }
                  }

                  // (resJson.data as ProductStock[]).find()
                  addItem(e);
                }
              }}
              key={e.uuid}
              className="  rounded-lg transition-all hover:shadow-lg shadow-gray-200 bg-white h-[240px] "
            >
              <div className="flex flex-col h-full cursor-pointer">
                <div className=" h-3/4 relative">
                  {e.default_picture ? (
                    <img
                      src={e.default_picture}
                      alt=""
                      className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                    />
                  ) : (
                    <img
                      src="/images/logo-niaga.png"
                      alt=""
                      className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                    />
                  )}
                  <div className=" absolute top-2 right-2 py-1 px-2 rounded  bg-opacity-70 bg-pink-500 text-xs text-white">
                    {e.product_category}
                  </div>
                </div>

                <div className="h-1/4 px-3 py-2 flex flex-col">
                  <p className="text-xs font-semibold"> {e.name}</p>
                  <div className="flex text-xs font-semibold text-orange-600">
                    {money(e.prices[0].amount)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal open={modalSearchOpen} onClose={() => setModalSearchOpen(false)}>
        <Modal.Body style={{ padding: 0, marginTop: 0 }}>
          <div className="flex flex-col space-y-2">
            <div className=" w-full ">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <MagnifyingGlassIcon
                    className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </div>
                <input
                  autoComplete="off"
                  type="search"
                  id="default-search"
                  className="block outline-none w-full p-4 ps-10 text-lg text-gray-900 border-0 rounded-full bg-transparent   "
                  placeholder={"Search ..."}
                  onChange={(val) => {
                    setSearch(val.target.value);
                  }}
                />
              </div>
            </div>
            <ul role="list" className="divide-y divide-gray-100">
              {searchProducts.map((e) => (
                <li
                  onClick={() => {
                    if (e.varians.length > 0) {
                      setSelectedProduct(e);
                      setModalVarianOpen(true);
                    } else {
                      addItem(e);
                    }
                    setSearchProducts([]);
                    setModalSearchOpen(false);
                  }}
                  key={e.uuid}
                  className="flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                >
                  <div className="flex">
                    <div className="w-16 h-16 mr-2 ">
                      {e.default_picture ? (
                        <img
                          src={e.default_picture}
                          alt=""
                          className="rounded-md h-full w-full object-cover bg-gray-600"
                        />
                      ) : (
                        <img
                          src="/images/logo-niaga.png"
                          alt=""
                          className="rounded-md  w-full object-cover bg-gray-600"
                        />
                      )}
                    </div>
                    <div>
                      <p className="font-semibold">{e.name}</p>
                      <div className="flex text-xl font-semibold text-orange-600">
                        {money(e.prices[0].amount)}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center"></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <ModalCustom
        isOpen={modalBarcodeOpen}
        setIsOpen={setModalBarcodeOpen}
        styles={{ padding: 0, height: 60 }}
      >
        <div className=" w-full ">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <QrCodeIcon
                className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </div>
            <input
              ref={barcodeRef}
              autoFocus
              type="search"
              id="default-search"
              className="block outline-none w-full p-4 ps-10 text-lg text-gray-900 border-0 rounded-full bg-transparent   "
              placeholder={"Input Barcode ..."}
              onChange={(val) => {
                if (val) {
                  getPosProductByBarcode(val.target.value)
                    .then((v) => v.json())
                    .then((v) => {
                      let products: Product[] = v.data;
                      if (products.length == 1) {
                        if (products[0].varians.length > 0) {
                          setSelectedProduct(products[0]);
                          setModalVarianOpen(true);
                        } else {
                          addItem(products[0]);
                        }
                      }
                    });
                }
              }}
            />
          </div>
        </div>
      </ModalCustom>
      <ModalSearch
        footer={
          selectedProduct != null && (
            <div className="p-4 flex justify-between">
              <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-orange-200 px-2 py-1 text-sm font-medium text-orange-900 hover:bg-orange-200"
                onClick={() => {
                  if (
                    selectedVarians.length == selectedProduct.varians.length
                  ) {
                    setSelectedVarians([]);
                    return;
                  }
                  setSelectedVarians([...selectedProduct.varians]);
                }}
              >
                {" "}
                <CheckCircleIcon
                  className=" h-3 w-3 mr-1"
                  aria-hidden="true"
                />{" "}
                Pilih Semua{" "}
              </button>
              <button
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent bg-green-200 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                onClick={() => {
                  for (const key in selectedVarians) {
                    if (
                      Object.prototype.hasOwnProperty.call(selectedVarians, key)
                    ) {
                      const element = selectedVarians[key];
                      addItem(selectedProduct, element);
                    }
                  }
                  setSelectedProduct(null);
                  setModalVarianOpen(false);
                  setSelectedVarians([]);
                }}
              >
                {" "}
                <WalletIcon className=" h-3 w-3 mr-1" aria-hidden="true" />{" "}
                Simpan{" "}
              </button>
            </div>
          )
        }
        hideSearch={true}
        isOpen={modalVarianOpen}
        setIsOpen={setModalVarianOpen}
        onChange={function (arg: string): void {}}
        onClose={() => {
          setSelectedProduct(null);
          setSelectedVarians([]);
        }}
      >
        {selectedProduct != null && (
          <ul role="list" className="divide-y divide-gray-100">
            {selectedProduct!.varians.map((e) => {
              let stock = 0;
              let selectedStock = varianStocks.find(
                (s) => s.varian_id == e.uuid
              );
              if (selectedStock) {
                stock = selectedStock.amount;
              }
              return (
                <li
                  key={e.uuid}
                  onClick={() => {
                    // addItem(selectedProduct, e)
                    if (
                      selectedVarians.filter((v) => v.uuid == e.uuid).length > 0
                    ) {
                      setSelectedVarians([
                        ...selectedVarians.filter((v) => v.uuid != e.uuid),
                      ]);
                    } else {
                      setSelectedVarians([...selectedVarians, e]);
                      if (
                        merchant != null &&
                        merchant!.check_stock &&
                        stock == 0
                      ) {
                        toaster.push(
                          <Message showIcon type={"warning"} closable>
                            Total Stok {stock} {selectedProduct.unit_name}
                          </Message>,
                          { placement: "bottomStart", duration: 3000 }
                        );
                      }
                    }
                  }}
                  className="flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                >
                  <div className="flex items-center mr-2">
                    <Avatar
                      marginRight={20}
                      name={parseVarian(e.data)}
                      url={""}
                    />
                    <div className="min-w-0">
                      <p className="text-sm font-semibold leading-6   ">
                        {parseVarian(e.data, true)}
                      </p>
                      {stock > 0 && (
                        <p className="text-xs">
                          <strong>Total Stok:</strong> {money(stock)}{" "}
                          {selectedProduct?.unit_name}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                  {selectedVarians.filter((v) => v.uuid == e.uuid).length >
                  0 ? (
                    <CheckCircleIcon className="w-7 text-green-600" />
                  ) : null}
                </li>
              );
            })}
          </ul>
        )}
      </ModalSearch>
    </div>
  );
};
export default OrderProduct;
