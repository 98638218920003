import { useContext, useEffect, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/dashboard";
import { Merchant } from "../model/merchant";
import { Profile } from "../model/profile";
import { LoadingContext } from "../objects/loading_context";
import { getMerchantDetail } from "../repositories/merchant";
import {
    getProfileStorage
} from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import RegularFlow from "../components/RegularFlow";
import RestoFlow from "../components/RestoFlow";

interface CashierPageProps {}

const CashierPage: FC<CashierPageProps> = ({}) => {
  const nav = useNavigate();
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [mounted, setMounted] = useState(false);
  const [merchant, setMerchant] = useState<Merchant>();


  useEffect(() => {
    setMounted(true);
  }, []);


  useEffect(() => {
    if (!mounted) return;
    initData();
  }, [mounted]);

  const initData = async () => {
    setProfile(await getProfileStorage());
    getMerchantDetail()
    .then((v) => v.json())
    .then((v) => setMerchant(v.data))
    .catch((err) => errorToast(`${err}`))
    .finally(() => {
      setIsLoading(false);
    });
   
  };


  return (
    <DashboardLayout permission="cashier_user">
      {merchant?.order_flow == "Regular" && <RegularFlow merchant={merchant} />}
      {merchant?.order_flow == "Resto" && <RestoFlow merchant={merchant} />}
    </DashboardLayout>
  );
};
export default CashierPage;
